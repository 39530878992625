import {
  Dispatch,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import {PaymentsWindowService} from '../services/PaymentsWindowService';
import {useTransactionsConfig} from './TransactionsConfigProvider';
import {useFeatureLogic} from '../hooks/useFeatureLogic';
import {useTransactionsState} from './TransactionsStateProvider';
import {useAuthentication} from './AuthenticationProvider';
import {Modal} from '@emporos/components/src/Modal';
import {useNetworkAvailable} from './NetworkAvailableProvider';
import {useIdleTimerContext} from 'react-idle-timer';
import {PaymentService} from '../services/PaymentService';
import {useTransaction} from '../hooks/useTransaction';
import {AuthClaim} from '../auth';
import {useSessionData} from './SessionDataProvider';
import {PaymentOptionResultListApiResponse} from '@emporos/api-enterprise';

export interface IPaymentsWindowState {
  title: string;
  subtitle: string;
  isInitialized: boolean;
  isOpen: boolean;
  isProcessing: boolean;
  isRefreshing: boolean;
  isSpinning: boolean;
  isSubmitButtonDisabled: boolean;
  returnFeature: string;
}

interface IPaymentsWindowProviderProps {
  state: IPaymentsWindowState;
  dispatch: Dispatch<Partial<IPaymentsWindowState>>;
  paymentsWindowService: PaymentsWindowService;
}

export const initialState: IPaymentsWindowState = {
  title: 'Payment Window Open',
  subtitle: 'Please do not close this window.',
  isInitialized: false,
  isOpen: false,
  isProcessing: false,
  isRefreshing: false,
  isSpinning: false,
  isSubmitButtonDisabled: false,
  returnFeature: '',
};

const PaymentsWindowContext = createContext<IPaymentsWindowProviderProps>({
  state: {...initialState},
  dispatch: () => null,
  paymentsWindowService: null as unknown as PaymentsWindowService,
});

let paymentsWindowService: PaymentsWindowService;

const PaymentsWindowProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const {session, setCurrentTransactionId, deleteTransactionFromLocalDb} =
    useTransactionsState();
  const {logout} = useAuthentication();
  const {loadSession} = useTransactionsConfig();
  const {online: isNetworkAvailable} = useNetworkAvailable();
  const {resume: idleTimerResume} = useIdleTimerContext();
  const online: MutableRefObject<boolean> = useRef(isNetworkAvailable);
  const {user} = useAuthentication();
  const token = user ? user.access_token : '';
  const paymentService = new PaymentService(token);
  const {transaction} = useTransaction();

  const {paymentOptionsResult} = useSessionData();

  const [state, dispatch] = useReducer<
    Reducer<IPaymentsWindowState, Partial<IPaymentsWindowState>>
  >(
    (
      state: IPaymentsWindowState,
      action: Partial<IPaymentsWindowState> = {},
    ) => {
      const newState = {...state, ...action};

      newState.title = newState.isRefreshing
        ? 'Refreshing Hilo Session'
        : initialState.title;

      newState.subtitle = newState.isRefreshing
        ? 'Please do not close this window.'
        : initialState.subtitle;

      if (newState.isProcessing) {
        newState.title = 'Processing Payment';
        newState.subtitle = 'Please do not close this window.';
      }

      newState.isSpinning = newState.isProcessing || newState.isRefreshing;
      newState.isSubmitButtonDisabled =
        newState.isProcessing || newState.isRefreshing;

      return newState;
    },
    {...initialState},
  );

  const navigateToFeature = (feature = '', transactionId = '') => {
    useFeatureLogic({
      setCurrentTransactionId,
      logout,
      clearSmsSent: deleteTransactionFromLocalDb,
      feature,
      transactionId,
    });

    dispatch({returnFeature: feature});
  };

  useEffect(() => {
    online.current = isNetworkAvailable;
  }, [isNetworkAvailable]);

  useEffect(() => {
    if (state.isInitialized) return;

    paymentsWindowService = new PaymentsWindowService(
      loadSession,
      online,
      dispatch,
      navigateToFeature,
      idleTimerResume,
    );
    dispatch({isInitialized: true});
  }, []);

  const renderModal = (isInProgress: boolean) => {
    return (
      <Modal
        visible={state.isOpen}
        data-testid="Modal__PaymentInProcess"
        icon="CreditCard"
        iconSpinning={state.isSpinning}
        disableSubmit={isInProgress ? false : state.isSubmitButtonDisabled}
        color={isInProgress ? 'warning' : 'primary'}
        title={isInProgress ? 'Payment In Progress' : state.title}
        subtitle={
          isInProgress ? 'Please do not close this window.' : state.subtitle
        }
        buttonText={isInProgress ? 'Check Status' : 'Cancel'}
        onContinue={() => {
          if (isInProgress) {
            const paymentOptionsArray = new Set(
              (
                paymentOptionsResult as PaymentOptionResultListApiResponse
              ).data?.map(o => o.displayName),
            );

            paymentService.OpenPaymentsDomain(
              transaction,
              Array.from(paymentOptionsArray),
              user?.profile[AuthClaim.Name] as string,
              session && session.station && session.station.number
                ? session.station.number
                : 0,
              paymentsWindowService,
              session?.paymentDeviceAddress || '',
              '',
            );
            return;
          } else {
            paymentsWindowService?.close();
          }
        }}
      />
    );
  };

  useEffect(() => {
    const paymentStatus = paymentsWindowService.getPaymentInProcess();
    if (paymentStatus === 'inProgress') {
      dispatch({isProcessing: true, isSubmitButtonDisabled: false});
    }
    dispatch({isInitialized: true});
  }, []);

  return (
    <PaymentsWindowContext.Provider
      value={{
        state,
        dispatch,
        paymentsWindowService,
      }}
    >
      <>
        {renderModal(state.isProcessing)}
        {children}
      </>
    </PaymentsWindowContext.Provider>
  );
};

const usePaymentsWindow = (): IPaymentsWindowProviderProps =>
  useContext(PaymentsWindowContext);

export {PaymentsWindowProvider, usePaymentsWindow};
