import {AuthClaim} from '@emporos/pos/src/auth/const';
import {User} from '@emporos/hilo-auth';
import {Session} from '@emporos/api-enterprise';
import {decryptPrependedIV, encryptPrependIV} from './crypto';

export const generateSessionKey = (user: User | null): string => {
  return user ? `emporos/session/${user.profile[AuthClaim.UserId]}` : '';
};

export type StoredSession = Omit<Session, 'transactions|accessCode'>;

export const generateStoredSessionString = (session: Session): string => {
  const storedSession = generateStoredSession(session);
  const jsonSession = JSON.stringify(storedSession);
  return encryptPrependIV(jsonSession, session.accessCode);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const generateStoredSession = (session: Session): StoredSession => {
  const {transactions, accessCode, ...staticSession} = session;
  return {
    ...staticSession,
  } as StoredSession;
};

export const parseStoredSession = (
  storedSessionString: string,
  accessCode: string,
): StoredSession | null => {
  try {
    const decryptedSession = decryptPrependedIV(
      storedSessionString,
      accessCode,
    );
    const storedUser = JSON.parse(decryptedSession) as StoredSession;
    if (storedUser.drawerDate && !(storedUser.drawerDate instanceof Date))
      storedUser.drawerDate = new Date(storedUser.drawerDate);
    return storedUser;
  } catch (error) {
    return null;
  }
};
