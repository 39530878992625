import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, PropsWithChildren, useCallback} from 'react';
import {
  ApiLogTypes,
  CustomerPayment,
  mapCustomerAccount,
  useBetaFeatures,
  useCardReaderCredentials,
  useCustomerSearch,
  useSessionData,
  useTransaction,
  useLog,
  useNetworkAvailable,
  usePaymentProvider,
  useSettings,
  useSignatures,
  useTotals,
  useTransactionsState,
  withChildPage,
  useGlobalData,
} from '../../../../../';
import {useApi} from '../../../../../contexts/ApiProvider';
import {Customer, Transaction} from '@emporos/api-enterprise';

export function pickCustomer(
  searchCustomer: Customer | null,
  transaction: Transaction | null,
): Customer | null {
  return searchCustomer || (transaction?.customer as Customer);
}

function CustomerPaymentIntegrationComponent({
  navigate,
}: PropsWithChildren<RouteComponentProps>): JSX.Element {
  const api = useApi();
  const {creditCardsOnFile, vantiv} = useBetaFeatures();
  const {creditCardPendingPaymentExpirationMinutes} = useSettings();
  const {credentials} = useCardReaderCredentials();
  const {customer, refresh} = useCustomerSearch();
  const {paymentTendersResult} = useGlobalData();
  const {settingsResult, paymentOptionsResult} = useSessionData();
  const {transaction, updateTransaction, processedPayments} = useTransaction();
  const {session, setSelectedPayment} = useTransactionsState();
  const signatureTypes = useSignatures();
  const {totals} = useTotals();
  const {logApi} = useLog();
  const {online} = useNetworkAvailable();
  const {NODE_ENV} = process.env;
  const {run: getEmployee} = api.GetEmployee();
  const {run: getEligibility} = api.GetEligibility();
  const {run: postAR} = api.PostCustomerAR();
  const {run: postPD} = api.PostCustomerPD();
  const paymentProvider = usePaymentProvider();

  const onGetCustomerClick = useCallback(() => {
    navigate && navigate('add-customer');
  }, [navigate]);

  assert(
    navigate,
    '<CustomerPayment /> must have a `navigate` prop.' + String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(transaction, 'Missing transaction');

  return (
    <CustomerPayment
      showCreditCardsOnFile={creditCardsOnFile}
      showVantiv={vantiv}
      showHostedPayment={session.hostedPaymentsEnabled}
      creditCardPendingPaymentExpirationMinutes={
        creditCardPendingPaymentExpirationMinutes
      }
      isOnline={online}
      navigateTo={navigate}
      credentials={credentials}
      customer={pickCustomer(customer, transaction)}
      transaction={transaction}
      processedPayments={processedPayments}
      totals={totals}
      settings={settingsResult?.data ?? []}
      paymentTenders={paymentTendersResult?.data ?? []}
      paymentOptions={paymentOptionsResult?.data ?? []}
      updateTransaction={updateTransaction}
      setSelectedPayment={setSelectedPayment}
      onSearchEligibility={query => {
        logApi(ApiLogTypes.EligibilityPending);
        return getEligibility({q: query});
      }}
      onGetCustomer={onGetCustomerClick}
      onGetEmployee={({employeeNumber, badgeNumber}) => {
        logApi(ApiLogTypes.EmployeePending);
        return getEmployee({
          employeeOrBadgeNumber: employeeNumber || badgeNumber,
        });
      }}
      onCreatePD={async (id: number) => {
        if (!id) {
          return undefined;
        }
        const response = await postPD({
          customerId: id || 0,
          siteId: session.siteId,
          stationId: session.stationId,
        });
        await refresh();
        return response.data
          ? mapCustomerAccount(id, response.data)
          : undefined;
      }}
      onCreateAR={async () => {
        if (!customer) {
          return undefined;
        }
        const response = await postAR({
          customerId: customer.id || 0,
          siteId: session.siteId,
          stationId: session.stationId,
        });
        await refresh();
        return response.data
          ? mapCustomerAccount(customer.id, response.data)
          : undefined;
      }}
      signatureTypes={signatureTypes}
      paymentProvider={paymentProvider}
    />
  );
}

export const CustomerPaymentIntegration = memo(
  withChildPage(CustomerPaymentIntegrationComponent),
);
