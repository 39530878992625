import {Transaction} from '@emporos/api-enterprise';
import {ButtonShape, ButtonSize, Header, Modal} from '@emporos/components';
import {formatCustomerName} from '../../../../utils';
import {useCallback, useState} from 'react';
import {navigate} from '@reach/router';
import {TransactionLogTypes, useLog} from '../../../../contexts';

interface PatientPayHeaderProps {
  transaction: Transaction;
  canDeleteTransaction: boolean;
  onRemoveTransaction: (transaction: Transaction) => void;
}

export const PatientPayHeader = ({
  transaction,
  canDeleteTransaction,
  onRemoveTransaction,
}: PatientPayHeaderProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {logUserSelection} = useLog();

  const customerName = transaction.customer
    ? formatCustomerName(transaction.customer)
    : '';

  const removeTransactionHandler = useCallback(() => {
    onRemoveTransaction(transaction);
    logUserSelection(TransactionLogTypes.RemoveTransaction, {
      item: transaction.transactionId,
    });
    navigate('/sales');
  }, [transaction, onRemoveTransaction]);

  return (
    <>
      <Header title={customerName} badgeText={transaction.roomNumber ?? ''}>
        <ButtonShape
          size={ButtonSize.Small}
          icon="Messageltp"
          disabled={true}
          data-testid="smsltp"
        />
        <ButtonShape
          size={ButtonSize.Small}
          icon="User"
          disabled={true}
          data-testid="customer-info-btn"
        />
        <ButtonShape
          disabled={!canDeleteTransaction}
          size={ButtonSize.Small}
          icon="Trash"
          onClick={() => setDeleteModalOpen(true)}
          data-testid="delete"
        />
      </Header>

      <Modal
        data-testid="delete-modal"
        visible={deleteModalOpen}
        icon="Trash"
        color="error"
        onCancel={() => setDeleteModalOpen(false)}
        onContinue={removeTransactionHandler}
        title="Delete This Transaction"
        subtitle="This transaction will be deleted and cannot be recovered. Would you like to continue?"
      />
    </>
  );
};
