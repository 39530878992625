import {WillCallRequest} from '@emporos/api-enterprise';
import {CompleteBarcodeComponent} from '@emporos/barcodes';
import {useMounted} from '@emporos/components';
import {Redirect} from '@reach/router';
import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Transaction,
  ItemSearchState,
  mapTransactionCustomer,
  OfflineCustomer,
  OfflineTransaction,
  useCustomerSearch,
  useCustomerWillCall,
  useSessionData,
  useNetworkAvailable,
  usePendingTransaction,
  useTransactionsState,
  withChildPage,
  WithPageProps,
  ManualRXFormData,
} from '../../../';
import {CustomerInfoPanel} from '../';

interface Props {
  canAddCustomer?: boolean;
  disableWillCall?: boolean;
}

export function isRxItem(state: unknown): state is ItemSearchState {
  return (
    typeof state === 'object' &&
    state !== null &&
    'rx' in state &&
    'fill' in state &&
    'site' in state
  );
}

function CustomerInfoWillCallComponent({
  canAddCustomer = true,
  disableWillCall = false,
  navigate,
}: PropsWithChildren<WithPageProps<Props>>) {
  const {online} = useNetworkAvailable();
  const {currentTransactionId} = useTransactionsState();

  if (!currentTransactionId) {
    return <Redirect to="/sales" noThrow />;
  }
  const {customer: searchCustomer, setCustomer} = useCustomerSearch();
  const {
    run: runCustomerWillCall,
    customer: willCallCustomer,
    loading: willCallLoading,
  } = useCustomerWillCall();
  const {run: putWillCall} = useCustomerWillCall();
  const {pmsName, barcodeName, barcodesResult} = useSessionData();
  const {transaction, transactionChanged, commit, tryAttachCustomer} =
    usePendingTransaction();
  const [initialRx, setInitialRx] = useState<ManualRXFormData>();

  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? (barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          ) as CompleteBarcodeComponent[])
        : [],
    [barcodesResult],
  );
  const customer = useMemo(() => transaction.customer, [transaction]);

  useEffect(() => {
    if (searchCustomer) {
      tryAttachCustomer(searchCustomer);
    }
  }, [searchCustomer]);

  useEffect(() => {
    if (willCallCustomer) {
      tryAttachCustomer(willCallCustomer);
    }
  }, [willCallCustomer]);

  useEffect(() => {
    if (initialRx && !customer) {
      runCustomerWillCall({
        ...initialRx,
        partial: initialRx.partial || '0',
      });
    }
  }, [initialRx, customer]);

  const mounted = useMounted();
  useEffect(() => {
    if (
      // We are within initial mount
      !mounted &&
      // and we have an existing customer
      customer &&
      (customer.code || customer.mrn) &&
      // and the customer has no prescriptions loaded
      (customer as OfflineCustomer).prescriptions === undefined
    ) {
      // load customer prescriptions
      putWillCall({
        customerCode: customer.code,
        mrn: customer.mrn,
      }).then(result => {
        const {data} = result;
        if (!data) {
          return;
        }
        const {
          customers: [cust],
          prescriptions,
        } = data;
        // and immediately commit prescriptions to transaction
        if (cust) {
          commit({
            customer: mapTransactionCustomer(cust, prescriptions),
          });
        }
      });
    }
  }, [transaction, customer, mounted]);

  const initialPrescription = useMemo(() => {
    if (initialRx) {
      return initialRx;
    }

    return null;
  }, [initialRx]);

  const onUnresolvedRxScan = useCallback(
    (willCallRequest: WillCallRequest) => {
      setInitialRx({
        rx: willCallRequest.rx || '',
        fill: willCallRequest.fill || '',
        site: willCallRequest.site || '',
        partial: willCallRequest.partial || '',
      });
    },
    [customer],
  );

  const onAddCustomerClick = useCallback(
    () => canAddCustomer && navigate && navigate('add-customer'),
    [],
  );

  const onCancel = useCallback(() => {
    canAddCustomer && setCustomer(null);
    navigate && navigate('../');
  }, []);

  const onSave = useCallback(
    (updates: Partial<Transaction>) => {
      commit(updates);
      navigate && navigate('../');
    },
    [navigate, commit],
  );

  return (
    <CustomerInfoPanel
      barcodeComponents={barcodeComponents}
      forceCanSave={transactionChanged}
      initialPrescription={initialPrescription}
      transactionChanged={transactionChanged}
      online={online}
      transaction={transaction as OfflineTransaction}
      loadingCustomer={willCallLoading}
      pmsName={pmsName}
      viewOnly={!canAddCustomer || disableWillCall}
      onAddCustomerClick={canAddCustomer ? onAddCustomerClick : undefined}
      onCancel={onCancel}
      onSave={onSave}
      onUnresolvedRxScan={onUnresolvedRxScan}
    />
  );
}

export const CustomerInfoIntegrationWillCall = memo(
  withChildPage(CustomerInfoWillCallComponent),
);
