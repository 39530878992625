import {createContext, useContext, useEffect, useState} from 'react';
import {AnalyticType, useAnalyticsProvider} from './';
import {User} from '@emporos/hilo-auth';

interface NetworkAvailableProps {
  online: boolean;
  tempCachedAccessCode: string;
  setTempCachedAccessCode: (value: string) => void;
  userNeedsRefreshed: User | null;
  setUserNeedsRefreshed: (value: User | null) => void;
}

export const NetworkAvailableContext = createContext<NetworkAvailableProps>(
  {} as NetworkAvailableProps,
);

export function NetworkAvailableProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const {track} = useAnalyticsProvider();
  const [timeSpentOffline, setTimeSpentOffline] = useState<number>(0);
  const [online, setOnline] = useState(navigator.onLine);
  const [tempCachedAccessCode, setTempCachedAccessCode] = useState<string>('');
  const [userNeedsRefreshed, setUserNeedsRefreshed] = useState<User | null>(
    null,
  );

  useEffect(() => {
    function _setOnline() {
      setOnline(true);
      if (timeSpentOffline) {
        track(AnalyticType.TimeOffline, {time: +new Date() - timeSpentOffline});
        setTimeSpentOffline(0);
      }
    }
    function _setOffline() {
      setOnline(false);
      setTimeSpentOffline(+new Date());
    }

    window.addEventListener('online', _setOnline);
    window.addEventListener('offline', _setOffline);

    return () => {
      window.removeEventListener('online', _setOnline);
      window.removeEventListener('offline', _setOffline);
    };
  }, [timeSpentOffline]);

  const contextValue = {
    online,
    tempCachedAccessCode,
    setTempCachedAccessCode,
    userNeedsRefreshed,
    setUserNeedsRefreshed,
  };

  return (
    <NetworkAvailableContext.Provider value={contextValue}>
      {children}
    </NetworkAvailableContext.Provider>
  );
}

export function useNetworkAvailable(): NetworkAvailableProps {
  return useContext(NetworkAvailableContext);
}
