import {
  ApiResponse,
  FeatureConfig,
  IPaymentService,
  Integration,
} from './IPaymentService';
import {
  CustomerCreditCardForIntegration,
  ManagementPayloadIntegration,
  TransactionConsolidate,
  TransactionPayloadIntegration,
} from '../api';
import {Transaction} from '@emporos/api-enterprise/src';
import {PaymentsWindowService} from './PaymentsWindowService';

export interface LinkToPayCounts {
  completed: number;
  errored: number;
  isProblem: boolean;
}

enum AcceptedPaymentType {
  Cash = 1,
  Check = 2,
  CreditCard = 8,
  AccountsReceivable = 12,
  StoreCoupon = 16,
  PrescriptionVoucher = 17,
  PayrollDeduction = 27,
  Unknown = 99,
}

const mapStringToAcceptedPaymentType = (
  paymentTypeString: string,
): AcceptedPaymentType | undefined => {
  switch (paymentTypeString) {
    case 'Cash':
      return AcceptedPaymentType.Cash;
    case 'Check':
      return AcceptedPaymentType.Check;
    case 'Card':
      return AcceptedPaymentType.CreditCard;
    case 'Account Receivable':
      return AcceptedPaymentType.AccountsReceivable;
    case 'Payroll Deduct':
      return AcceptedPaymentType.PayrollDeduction;
    default:
      return undefined;
  }
};

export class PaymentService implements IPaymentService {
  private PAYMENT_API_URL: string;

  private TOKEN = '';

  constructor(token: string) {
    const paymentBaseUrl = process.env.PAYMENT_BASE_URL ?? '';
    const tenantId = process.env.TENANT_ID ?? '';

    this.PAYMENT_API_URL = paymentBaseUrl + '/api/' + tenantId + '/';

    this.TOKEN = token;
  }

  async GetEnabledFeatures(
    siteId?: number,
    stationId?: number,
  ): Promise<FeatureConfig[]> {
    let url = this.PAYMENT_API_URL + 'integration/enabledFeatures';
    if (siteId) url += '/' + siteId;
    if (stationId) url += '/' + stationId;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });
      const res: ApiResponse<FeatureConfig[]> = await response.json();
      return res.data;
    } catch (error) {
      console.error(new Error('Error while making GET request: ' + error));
      return [];
    }
  }

  async OpenPaymentsDomain(
    transaction: Transaction,
    paymentOptions: string[],
    username: string,
    stationNumber: number,
    paymentsWindowService: PaymentsWindowService,
    paymentDeviceAddress: string | null = '',
    transactionPaymentId: string | null = null,
  ): Promise<void> {
    const getCode = this.PAYMENT_API_URL + 'integration/init';

    // Populate payment options
    let acceptedPaymentTypes = paymentOptions.map(
      (paymentTypeString: string) => {
        let paymentType: AcceptedPaymentType | undefined =
          mapStringToAcceptedPaymentType(paymentTypeString);

        // Disable Account Receivable if customerId is 0, null, or undefined
        const disabled: boolean =
          paymentType === AcceptedPaymentType.AccountsReceivable &&
          (!transaction.customerId || transaction.customerId === 0);

        // Modify paymentTypeString based on AcceptedPaymentType
        switch (paymentType) {
          case AcceptedPaymentType.AccountsReceivable:
            paymentTypeString = 'AR';
            break;
          case AcceptedPaymentType.PayrollDeduction:
            paymentTypeString = 'PD';
            break;
          case AcceptedPaymentType.CreditCard:
            paymentTypeString = 'Credit';
            break;
          case AcceptedPaymentType.Unknown:
            paymentType = undefined; // Remove Unknown payment type
            break;
          default:
            break;
        }

        return {
          paymentType:
            paymentType !== undefined
              ? paymentType
              : AcceptedPaymentType.Unknown,
          name: paymentTypeString,
          disabled: disabled,
        };
      },
    );

    // Add "Custom" payment type if Unknown payment type exists
    const hasUnknownPaymentType = acceptedPaymentTypes.some(
      option => option.paymentType === AcceptedPaymentType.Unknown,
    );
    // Remove all instances of Unknown payment type
    acceptedPaymentTypes = acceptedPaymentTypes.filter(
      option => option.paymentType !== AcceptedPaymentType.Unknown,
    );

    if (hasUnknownPaymentType) {
      acceptedPaymentTypes.push({
        paymentType: AcceptedPaymentType.Unknown,
        name: 'Custom',
        disabled: false,
      });
    }

    //add station Number to the transaction
    (transaction as TransactionConsolidate).stationNumber = stationNumber;

    const updatedTr: TransactionPayloadIntegration = {
      transaction: transaction,
      acceptedPaymentTypes: acceptedPaymentTypes,
      loggedInUsername: username,
      transactionId: transaction.transactionId,
      paymentDeviceAddress,
      transactionPaymentId,
    };

    updatedTr.transaction.customer?.creditCards.forEach(card => {
      const expirationString = card.expiration.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
      });
      (card as CustomerCreditCardForIntegration).expirationString =
        expirationString;
    });

    try {
      // Make POST request to Payment API
      const response = await fetch(getCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(updatedTr),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.log('Error messages from the API:', dataResponse.errorMessages);
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);

      await paymentsWindowService?.open('pay/' + dataResponse.data.urlCode);
    } catch (error) {
      console.log('Error while making POST request:', error);
      throw error;
    }
  }

  async OpenPaymentsManage(
    username: string,
    sessionId: string,
    siteId: number,
    paymentsWindowService: PaymentsWindowService,
  ): Promise<void> {
    const getCode = this.PAYMENT_API_URL + 'integration/init-manage';

    const mangePyl: ManagementPayloadIntegration = {
      sessionId: sessionId,
      username: username,
      siteId: siteId,
      transactionId: '00000-0000-0000-0000-0000-0000',
    };

    try {
      // Make POST request to Payment API
      const response = await fetch(getCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(mangePyl),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.log('Error messages from the API:', dataResponse.errorMessages);
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);

      await paymentsWindowService?.open(
        'manage/' +
          dataResponse.data.urlCode +
          '/siteId/' +
          siteId +
          '/sessionId/' +
          sessionId +
          '/username/' +
          username,
      );
    } catch (error) {
      console.log('Error while making POST request:', error);
      throw error;
    }
  }

  async RedirectLtpSms(
    transaction: Transaction,
    username: string,
    stationNumber: number,
    paymentDeviceAddress: string | null | undefined,
    paymentsWindowService: PaymentsWindowService,
  ): Promise<void> {
    const getCode = this.PAYMENT_API_URL + 'integration/init';

    const acceptedPaymentTypes = [];

    acceptedPaymentTypes.push({
      paymentType: AcceptedPaymentType.Unknown,
      name: 'Custom',
      disabled: false,
    });

    //add station Number to the transaction
    (transaction as TransactionConsolidate).stationNumber = stationNumber;

    const updatedTr: TransactionPayloadIntegration = {
      transaction: transaction,
      acceptedPaymentTypes: acceptedPaymentTypes,
      loggedInUsername: username,
      transactionId: transaction.transactionId,
      paymentDeviceAddress,
    };

    try {
      // Make POST request to Payment API
      const response = await fetch(getCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(updatedTr),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.log('Error messages from the API:', dataResponse.errorMessages);
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);
      const ltpsms = 'pay/ltp/sms/' + dataResponse.data.urlCode;

      await paymentsWindowService?.open(ltpsms);
    } catch (error) {
      console.log('Error while making POST request:', error);
      throw error;
    }
  }

  async GetLtpCounts(siteId: number): Promise<LinkToPayCounts> {
    let result = {
      completed: 0,
      errored: 0,
      isProblem: false,
    };

    try {
      const getCount = this.PAYMENT_API_URL + 'linktopay/siteId/' + siteId;
      const response = await fetch(getCount);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<LinkToPayCounts> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.log('Error messages from the API:', dataResponse.errorMessages);
        result.isProblem = true;
      }

      result = {...result, ...dataResponse.data};
    } catch (error) {
      console.log('Error while making GET request:', error);
      result.isProblem = true;
    }

    return result;
  }

  private setCookie(name: string, value: string, minutes: number): void {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    const isLocalhost = window.location.hostname.includes('localhost');
    const domain = isLocalhost
      ? ''
      : '; domain=' + window.location.hostname.split('.').slice(-2).join('.');
    const sameSite = isLocalhost ? 'Lax' : 'Strict';
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; path=/' +
      domain +
      '; SameSite=' +
      sameSite +
      '; Secure';
  }
}
