import {TransactionStatusEnum} from '@emporos/api-enterprise';
import {
  RowSidebarStatus,
  TabBar,
  TabItemWithNotification,
} from '@emporos/components';

import {
  RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router';
import {Fragment, memo, useState} from 'react';
import {
  CompletedSidebar,
  Transaction,
  TransactionConsolidate,
  NavigationLogTypes,
  PaymentsSidebarIntegration as PaymentsSidebar,
  SalesSidebar,
  SettingsSidebar,
  useSessionData,
  useLog,
  useTransactionsState,
  useAuthentication,
  AuthClaim,
  useNetworkAvailable,
} from '../';
import styled from 'styled-components';
import {PaymentService} from '../services/PaymentService';
import {usePaymentsWindow} from '../contexts/PaymentsWindowProvider';
import {useIdleTimerContext} from 'react-idle-timer';
import {ConsoleLogger} from '../utils/console-logger';

const PD_TAB = 1;
const COMPLETED_TAB = 2;
export const fullHeightStack = {minHeight: '100%'};
export const behindTabBarStackingContext = {
  position: 'relative' as const,
  zIndex: 0,
  flex: 1,
  overflowY: 'auto' as const,
};

function indexToUrl(index: number): string {
  switch (index) {
    case 0:
      return '/sales';
    case 2:
      return '/sales/completed';
    case 3:
      return '/sales/settings';
    default:
      return '';
  }
}

const ToolbarDiv = styled.div`
  position: absolute;
  bottom: 36px;
  zindex: 1;
  width: 294px;

  // Media query for screens up to 1024px in width
  @media (max-width: 1180px) {
    width: 233px;
  }
`;

export const transactionSynced = (
  transaction: Transaction | TransactionConsolidate,
): RowSidebarStatus => {
  if ((transaction as TransactionConsolidate).isSynced === false) {
    return 'unsynced';
  } else if (transaction.status === TransactionStatusEnum.Complete) {
    return 'synced';
  } else if (
    transaction.status == TransactionStatusEnum.Accepted ||
    transaction.status == TransactionStatusEnum.Error
  ) {
    return 'error';
  } else {
    return 'synced';
  }
};

interface NavigationProps extends RouteComponentProps {
  onNavigationRequest(index: number): void;
  loading: boolean;
  pathname: string;
}

function Navigation({loading, pathname, onNavigationRequest}: NavigationProps) {
  const {ltpCountsResult, enabledFeatures, settingsResult} = useSessionData();
  const {online} = useNetworkAvailable();

  const onHoldDisabled =
    !enabledFeatures.map(ef => ef.feature).includes('LinkToPay') ||
    !settingsResult ||
    !settingsResult.data?.some(({name, value}) => {
      return name === 'LinkToPaySMSEnabled' && value === '1';
    }) ||
    loading ||
    !online;

  let active: number;
  if (/settings/.test(pathname)) {
    active = 3;
  } else if (/completed/.test(pathname)) {
    active = 2;
  } else {
    active = 0;
  }

  return (
    <ToolbarDiv>
      <TabBar
        active={active}
        onSelect={onNavigationRequest}
        items={[
          {
            icon: 'CashRegister',
          },
          {
            icon: (
              <TabItemWithNotification
                icon="HoldTransaction"
                notifyRed={online}
                countRed={ltpCountsResult?.errored}
                notifyGreen={online}
                countGreen={ltpCountsResult?.completed}
                showErrorAlert={ltpCountsResult?.isProblem && online}
              />
            ),
            disabled: onHoldDisabled,
          },
          {
            icon: 'CheckmarkClipboard',
            disabled: loading,
          },
          {icon: 'Cog', disabled: loading},
        ]}
      />
    </ToolbarDiv>
  );
}

function SidebarComponent() {
  const navigate = useNavigate();
  const [requestedUrl, setRequestedUrl] = useState<null | string>(null);
  const {logUserSelection} = useLog();
  const {setCurrentTransactionId} = useTransactionsState();
  const {pathname} = useLocation();
  const {loading} = useSessionData();
  const {pause: idleTimerPause} = useIdleTimerContext();
  const {user} = useAuthentication();
  const {session} = useTransactionsState();
  const {paymentsWindowService} = usePaymentsWindow();
  const token = user ? user.access_token : '';
  const userName = user?.profile[AuthClaim.Name] as string;
  const consoleLogger = new ConsoleLogger();

  function onNavigationRequest(index: number) {
    if (index === PD_TAB) {
      openPaymentsDomain().catch(error => {
        consoleLogger.logError(
          'Error occurred when opening Payments Domain:',
          error,
        );
      });
    } else {
      setRequestedUrl(indexToUrl(index));
      logUserSelection(NavigationLogTypes.UserNavigationRequested, {
        url: indexToUrl(index),
      });
      if (index === COMPLETED_TAB) {
        setCurrentTransactionId('');
      }
    }
  }

  async function openPaymentsDomain() {
    idleTimerPause();
    const paymentService = new PaymentService(token);
    await paymentService.OpenPaymentsManage(
      userName,
      session.sessionId,
      session.siteId,
      paymentsWindowService,
    );
  }

  function onCancelNavigation() {
    logUserSelection(NavigationLogTypes.UserNavigationCancelled, {
      url: requestedUrl,
    });
    setRequestedUrl(null);
  }

  function onConfirmNavigation(url?: string) {
    if (typeof url === 'string') {
      logUserSelection(NavigationLogTypes.UserNavigating, {url});
      setRequestedUrl(null);
      return navigate(url);
    }
    if (requestedUrl) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: requestedUrl,
      });
      setRequestedUrl(null);
      return navigate(requestedUrl);
    }
  }

  return (
    <>
      <Router primary={false} component={Fragment}>
        <CompletedSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="completed/*"
        />
        <SettingsSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="settings/*"
        />
        <SalesSidebar
          default
          requestedUrl={requestedUrl}
          onConfirmNavigation={onConfirmNavigation}
        />

        <PaymentsSidebar path="patient-pay-transactions/*" />
        <PaymentsSidebar path="transactions/payments/*" />
      </Router>
      {!pathname.includes('patient-pay-transactions') &&
        !/payments/.test(pathname) && (
          <Navigation
            default
            onNavigationRequest={onNavigationRequest}
            loading={loading}
            pathname={pathname}
          />
        )}
    </>
  );
}

export const Sidebar = memo(SidebarComponent);
