import {
  BaseAPI,
  HTTPHeaders,
  HTTPMethod,
  HTTPQuery,
} from '@emporos/api-enterprise';
import {base64EncArr} from '../utils/base64';

export class EncryptedBaseApi extends BaseAPI {
  GenerateHeaders(sessionId: string): HTTPHeaders {
    const headerParameters: HTTPHeaders = {};
    headerParameters['X-Hilo-Encrypted'] = 'true';
    headerParameters['X-Hilo-SessionId'] = sessionId;
    headerParameters['Content-Type'] = 'application/json; v=1.5';
    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === 'function') {
        headerParameters['Authorization'] = this.configuration.accessToken(
          'oauth2',
          ['clientapi'],
        );
      } else {
        headerParameters['Authorization'] = this.configuration.accessToken;
      }
    }
    return headerParameters;
  }

  ConvertToEncodedString(buffer: Uint8Array): string {
    const cipherText = encodeURIComponent(base64EncArr(buffer));
    return cipherText;
  }

  async MakeRequest(
    path: string,
    body: string,
    headerParameters: HTTPHeaders,
    queryParameters: HTTPQuery,
    method: HTTPMethod,
  ): Promise<Response> {
    return await this.request({
      path: path,
      method: method,
      headers: headerParameters,
      query: queryParameters,
      body: body,
    });
  }
}
