import {
  Stack,
  PriceFooter,
  FooterGroup,
  Button,
  Variant,
  VerificationModal,
} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import {TotalsResult} from '../../../../hooks';
import {
  Transaction,
  TransactionUpdates,
  useAlertState,
  useBetaFeatures,
  useNetworkAvailable,
} from '../../../../contexts';
import {mapComplianceIndicators, mapIdCheckIndicators} from '../payments';
import {Setting} from '@emporos/api-enterprise';
import {mergeTaxes} from '../../../../utils/taxes';
import {OfflineSynced, OfflineTransaction} from '../../../../api';
import {useCallback, useState} from 'react';
import {normalizeCustomer} from '../../../../utils';

interface PatientPayFooterProps {
  totals: TotalsResult;
  transaction: Transaction;
  settings: Setting[];
  onUpdateTransaction: (updates: TransactionUpdates) => void;
  navigate: NavigateFn;
}

export const PatientPayFooter = ({
  totals,
  transaction,
  settings,
  onUpdateTransaction,
  navigate,
}: PatientPayFooterProps) => {
  const {online} = useNetworkAvailable();
  const {notification} = useAlertState();
  const {verificationModal} = useBetaFeatures();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const proceedToComplianceIsDisabled = totals.totalDue > 0;

  const {isNplex, isControlledSubstance, isRequiredAge} =
    mapIdCheckIndicators(transaction);

  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const showComplianceUrl =
    showCounsel || showHipaa || showRelationship
      ? './payments/compliance'
      : './payments/customer-payment';

  const url =
    !transaction.identification &&
    ((isNplex && transaction.pseCheckResult !== 1) ||
      isControlledSubstance ||
      isRequiredAge)
      ? './payments/id-check'
      : showComplianceUrl;

  const continueTransaction = () => {
    if (isNplex && transaction.pseCheckResult !== 1 && !online) {
      notification({
        title: 'Offline Error: PSE Items',
        description:
          'This transaction cannot move forward while offline with unapproved PSE items in the cart.',
        type: 'warning',
        icon: 'Warning',
      });
      return;
    }
    navigate(url, {
      state: {animatePanel: true},
    });
  };

  const updateTransaction = useCallback(() => {
    setShowVerificationModal(false);
    onUpdateTransaction({
      isVerified: true,
    } as Partial<Transaction> & OfflineSynced);
    return continueTransaction();
  }, [totals]);

  const proceedToComplianceHandler = () => {
    setShowVerificationModal(false);

    if (
      transaction.items.filter(({rx}) => rx).length > 0 &&
      !(transaction as OfflineTransaction)?.isVerified
    ) {
      setShowVerificationModal(true);
    } else {
      setShowVerificationModal(false);

      onUpdateTransaction({
        isSynced: false,
        taxableSubTotal: totals.taxableSubTotal,
        discount: totals.discount,
        subTotal: totals.subTotal,
        totalSale: totals.transactionTotal,
        totalTax: totals.salesTax,
        qhpRxAmount: totals.qhpRxAmount,
        qhpAmount: totals.qhpAmount,
        qhpRxQty: totals.qhpRxQty,
        qhpOtherAmount: totals.qhpOtherAmount,
        qhpOtherQty: totals.qhpOtherQty,
        taxes: mergeTaxes(transaction.taxes, totals.transactionTaxes),
      } as Partial<Transaction> & OfflineSynced);
      continueTransaction();
    }
  };

  return (
    <>
      <Stack style={{marginTop: 0}}>
        <PriceFooter totals={totals} style={{marginBottom: 16, marginTop: 0}} />
        <FooterGroup>
          <Button
            data-testid="leave-in-queue-btn"
            variant={Variant.Secondary}
            flex
            onClick={() => navigate('/sales')}
          >
            Leave in Queue
          </Button>
          <Button
            flex
            data-testid="proceed-compliance-btn"
            disabled={proceedToComplianceIsDisabled}
            onClick={proceedToComplianceHandler}
          >
            Proceed to Compliance
          </Button>
        </FooterGroup>
      </Stack>
      {verificationModal && transaction.customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(transaction.customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={updateTransaction}
        />
      )}
    </>
  );
};
