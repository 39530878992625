import {SignatureType} from '@emporos/api-enterprise';
import {
  ITEM_TYPE_IDS,
  getPaymentType,
  useGlobalData,
  useTransaction,
} from '../';

export const useSignatures = (): Array<SignatureType> => {
  const {transaction} = useTransaction();
  const {items, payments} = transaction;
  const {paymentTendersResult, signatureTypesResult} = useGlobalData();

  const hasNplex = () =>
    items.some(
      ({itemTypeID, rx}) => !rx && itemTypeID === ITEM_TYPE_IDS.METH_FREE,
    );

  const hasRx = () => items.some(({rx}) => rx);

  const hasSignatureRequired = () =>
    items.some(({signatureIndicator}) => signatureIndicator);

  const hasCreditCardPayment = () =>
    payments.some(
      p =>
        getPaymentType(
          p.paymentTypeID || 0,
          paymentTendersResult?.data || [],
        ) === 'Credit Card',
    );

  const hasARPayment = () =>
    payments.some(p => {
      const paymentType = getPaymentType(
        p.paymentTypeID || 0,
        paymentTendersResult?.data || [],
      );
      return paymentType === 'AR' || paymentType === 'PD';
    });

  const signatures: Array<SignatureType> = [];
  if (hasNplex()) {
    const nplex = signatureTypesResult?.find(
      type => type.description === 'PSE/NPLEX',
    );
    nplex && signatures.push(nplex);
  }
  if (hasRx()) {
    const rx = signatureTypesResult?.find(
      type => type.description === 'Rx/Prescription',
    );
    rx && signatures.push(rx);
  }
  if (hasARPayment()) {
    const ar = signatureTypesResult?.find(
      type => type.description === 'Accounts Receivable',
    );
    ar && signatures.push(ar);
  }
  if (hasCreditCardPayment()) {
    const cc = signatureTypesResult?.find(
      type => type.description === 'Credit Card',
    );
    cc && signatures.push(cc);
  }
  if (hasSignatureRequired()) {
    const signatureRequired = signatureTypesResult?.find(
      type => type.description === 'Item Restrictions',
    );
    signatureRequired && signatures.push(signatureRequired);
  }
  return signatures;
};
