/**
 * These are creators functions to create test fixture data for application code.
 * It is expected that *all* types that are necessary in application code could
 * be constructed in here.
 *
 * Status: there are currently a lot of these scattered throughout application
 * code that will be moved over here as tests are updated over the coming weeks.
 **/

import {
  BarcodeCollection,
  CashDrawer,
  Customer,
  Customer as SessionCustomer,
  CustomerAccount,
  CustomerCreditCard,
  CustomerPhone,
  CustomerSearchMatch,
  Transaction,
  TransactionExtra,
  TransactionIdentification,
  TransactionItem,
  TransactionPayment,
  TransactionSignature,
  TransactionStatusEnum,
  TransactionTax,
  OtcItem,
  PaymentOptionResult,
  PaymentType,
  Phone,
  Prescription,
  Relationship,
  Session,
  SessionStatusEnum,
  Setting,
  SignatureImage,
  SignatureType,
  Site,
  Station,
  TaxGroup,
  CustomerResponse,
  CustomerAccountResponse,
} from './gen';

export const createOtcItem = (props: Partial<OtcItem>): OtcItem => ({
  id: 0,
  itemNumber: 'x',
  description: '',
  itemType: {id: 0, name: ''},
  taxableIndicator: false,
  discountIndicator: false,
  departmentId: 0,
  salesUnitOfMeasureId: 0,
  listPrice: 0,
  receiptDescription: '',
  qhpIndicator: false,
  qhpPrivateIndicator: false,
  controlGrams: 0,
  counselPromptIndicator: false,
  requiredAge: 0,
  updateInventoryIndicator: false,
  lastCost: 0,
  attachments: [],
  proofOfIdRequired: false,
  signatureIndicator: false,
  taxGroupId: 0,
  taxGroupType: '',
  ...props,
});

export const createPrescription = (
  props: Partial<Prescription>,
): Prescription => ({
  discontinuedIndicator: 0,
  partialFillIndicator: 0,
  plans: [],
  price: 0,
  medicationStatus: 0,
  ...props,
});

export const createTransaction = (
  props?: Partial<Transaction>,
): Transaction => ({
  transactionId: '',
  sessionId: '',
  serverTransactionID: 0,
  serverTransactionNumber: 0,
  saleDate: new Date(),
  subTotal: 0,
  taxableSubTotal: 0,
  totalCost: 0,
  discount: 0,
  totalTax: 0,
  totalSale: 0,
  qhpRxQty: 0,
  qhpRxAmount: 0,
  qhpOtherQty: 0,
  qhpOtherAmount: 0,
  qhpCount: 0,
  qhpAmount: 0,
  qhpPartial: false,
  station: 0,
  employeeID: 0,
  taxExemptIndicator: false,
  xFileIndicator: false,
  recordStatus: '',
  sourceID: 0,
  onHoldAtServerIndicator: false,
  saleSiteID: 0,
  salesAuditIndicator: false,
  onDeliveryBypassCounselPrompt: false,
  onDeliveryBypassHIPAAPrompt: false,
  items: [],
  payments: [],
  taxes: [],
  signatures: [],
  extras: [],
  pseCheckResult: 0,
  status: TransactionStatusEnum.Active,
  ...props,
});

export const createTransactionItem = (
  props?: Partial<TransactionItem>,
): TransactionItem => ({
  transactionItemId: '',
  transactionId: '',
  itemID: 0,
  quantity: 0,
  listPrice: 0,
  price: 0,
  itemListPrice: 0,
  itemTypeID: 0,
  discountPercent: 0,
  discount: 0,
  extension: 0,
  extensionBeforeDiscount: 0,
  qhpIndicator: false,
  qhpPrivateIndicator: false,
  taxableIndicator: false,
  taxGroupID: 0,
  priceChangeIndicator: false,
  discountableIndicator: false,
  saleUnitOfMeasure: 0,
  departmentID: 0,
  controlGrams: 0,
  inventoryGroupID: 0,
  updateInventoryIndicator: false,
  kitIndicator: false,
  serialNumberIndicator: false,
  rentalIndicator: false,
  saleIndicator: false,
  type2BarcodeIndicator: false,
  weighedItemIndicator: false,
  departmentKeyIndicator: false,
  attachmentIndicator: false,
  manualWeighedIndicator: false,
  commitStockIndicator: false,
  negativeInventoryIndicator: false,
  directShipmentIndicator: false,
  signatureIndicator: false,
  ebtIndicator: false,
  wicIndicator: false,
  controlledSubstanceIndicator: false,
  dispensable: false,
  requiresHIPAACompliance: false,
  discontinuedIndicator: false,
  ...props,
});

export const createTransactionTax = (
  props?: Partial<TransactionTax>,
): TransactionTax => ({
  transactionTaxId: '',
  transactionId: '',
  taxID: 0,
  taxRateID: 0,
  ...props,
});

export const createSignatureTypes = (): SignatureType[] => [
  {
    signatureTypeId: 1,
    description: 'Rx/Prescription',
  },
  {
    signatureTypeId: 2,
    description: 'Item Restrictions',
  },
  {signatureTypeId: 3, description: 'PSE/NPLEX'},
  {
    signatureTypeId: 4,
    description: 'Accounts Receivable',
  },
  {
    signatureTypeId: 5,
    description: 'Credit Card',
  },
];

export const createPaymentTenders = (): PaymentType[] => [
  {
    id: 1,
    description: 'Cash Money',
    name: 'Cash',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 2,
    description: 'Check',
    name: 'Check',
    paymentNumberRequired: true,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 3,
    description: 'Visa',
    name: 'Visa',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 4,
    description: 'MC',
    name: 'MC',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 5,
    description: 'Discover',
    name: 'Disc',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 6,
    description: 'American Express',
    name: 'Amex',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 7,
    description: 'Store Credit',
    name: 'Store Credit',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 8,
    description: 'Gift Card',
    name: 'Gift Card',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 9,
    description: 'Travelers Check',
    name: 'Travelers Check',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 10,
    description: 'Deposit Used',
    name: 'Deposit Used',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 11,
    description: 'Coupon',
    name: 'Coupon',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 12,
    description: 'In House Charge',
    name: 'AR',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 13,
    description: 'Corporate Check',
    name: 'Corporate Check',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 14,
    description: '3rd Party Charge',
    name: '3rd Party Charge',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 15,
    description: 'Diners Club',
    name: 'DCCB',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 16,
    description: 'Store Coupon',
    name: 'User1',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 17,
    description: 'Prescription Voucher',
    name: 'User2',
    paymentNumberRequired: true,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 18,
    description: 'User3',
    name: 'User3',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 19,
    description: 'User4',
    name: 'User4',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 20,
    description: 'User5',
    name: 'User5',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 21,
    description: 'Monthly Plan',
    name: 'MP',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 22,
    description: 'On Approval Ck',
    name: 'OnAppCk',
    paymentNumberRequired: true,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 23,
    description: 'On Approval CC',
    name: 'OnAppCC',
    paymentNumberRequired: true,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 24,
    description: 'AR 3rd Party',
    name: 'AR 3rd Party',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 25,
    description: 'AR On Account',
    name: 'AR On Account',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 26,
    description: 'Debit',
    name: 'Debit',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 27,
    description: 'Payroll Deduct',
    name: 'Payroll Deduct',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 28,
    description: 'AR INS Charge',
    name: 'AR INS Charge',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 29,
    description: 'Credit Card Waiting Approval',
    name: 'CCWA',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: true,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 30,
    description: 'Credit Card Declined',
    name: 'CCD',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 31,
    description: 'FSA Card',
    name: 'FSA',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: true,
    limitToBalanceIndicator: false,
    externalChargeIndicator: false,
  },
  {
    id: 32,
    description: 'AR DME Charge',
    name: 'AR DME Charge',
    paymentNumberRequired: false,
    customerRequiredIndicator: true,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 33,
    description: 'Schrute Bucks',
    name: 'User6',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 34,
    description: 'Offline CC Authorization',
    name: 'OfflineCCAuthorization',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: false,
  },
  {
    id: 35,
    description: 'Quickcharge',
    name: 'Quickcharge',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: true,
  },
  {
    id: 36,
    description: 'Bursar',
    name: 'Bursar',
    paymentNumberRequired: false,
    customerRequiredIndicator: false,
    creditCardIndicator: false,
    limitToBalanceIndicator: true,
    externalChargeIndicator: true,
  },
];

export const createPaymentOptions = (): PaymentOptionResult[] => [
  {
    paymentOptionId: 4,
    displayName: 'Cash',
    constantName: 'PaymentCash',
    description: 'Cash payment Option',
  },
  {
    paymentOptionId: 5,
    displayName: 'Check',
    constantName: 'PaymentCheck',
    description: 'Check payment Option',
  },
  {
    paymentOptionId: 6,
    displayName: 'Card',
    constantName: 'PaymentCard',
    description: 'Card Payment Option',
  },
  {
    paymentOptionId: 7,
    displayName: 'Account Receivable',
    constantName: 'PaymentAR',
    description: 'In House Charge',
  },
  {
    paymentOptionId: 8,
    displayName: 'Payroll Deduct',
    constantName: 'PaymentPD',
    description: 'Payroll Deduction',
  },
  {
    paymentOptionId: 9,
    displayName: 'User1',
    constantName: 'PaymentUser1',
    description: 'User Defined Tender Option 1',
  },
  {
    paymentOptionId: 10,
    displayName: 'User2',
    constantName: 'PaymentUser2',
    description: 'User Defined Tender Option 2',
  },
  {
    paymentOptionId: 11,
    displayName: 'User3',
    constantName: 'PaymentUser3',
    description: 'User Defined Tender Option 3',
  },
  {
    paymentOptionId: 12,
    displayName: 'User4',
    constantName: 'PaymentUser4',
    description: 'User Defined Tender Option 4',
  },
  {
    paymentOptionId: 13,
    displayName: 'User5',
    constantName: 'PaymentUser5',
    description: 'User Defined Tender Option 5',
  },
  {
    paymentOptionId: 14,
    displayName: 'User6',
    constantName: 'PaymentUser6',
    description: 'User Defined Tender Option 6',
  },
];

export const createSettings = (): Setting[] => [
  {
    name: 'SiteID',
    value: '1050000000',
  },
  {
    name: 'SiteShortName',
    value: 'Vantiv Test 1.0',
  },
  {
    name: 'SiteName',
    value: 'Vantiv Test 1.0',
  },
  {
    name: 'SiteAddress1',
    value: '8514 McAlpine Park Dr.',
  },
  {
    name: 'SiteAddress2',
    value: '135',
  },
  {
    name: 'SiteCity',
    value: 'Charlotte',
  },
  {
    name: 'SiteState',
    value: 'NC',
  },
  {
    name: 'SiteZipCode',
    value: '28211',
  },
  {
    name: 'SiteCounty',
    value: '',
  },
  {
    name: 'SitePhone',
    value: '8777162403',
  },
  {
    name: 'SiteFax',
    value: '8777162404',
  },
  {
    name: 'SiteCountry',
    value: 'US',
  },
  {
    name: 'StationID',
    value: '17',
  },
  {
    name: 'StationDescription',
    value: '',
  },
  {
    name: 'StationType',
    value: 'POS',
  },
  {
    name: 'PrintCustomerEntryLines',
    value: 'Returns',
  },
  {
    name: 'ReceiptState',
    value: 'NC',
  },
  {
    name: 'ReceiptZipCode',
    value: '28211',
  },
  {
    name: 'ReceiptPhone1',
    value: '704-567-9426',
  },
  {
    name: 'ReceiptPhone2',
    value: '',
  },
  {
    name: 'ReceiptFax',
    value: '',
  },
  {
    name: 'ReceiptURL',
    value: 'https://tinyurl.com/ESCPOS-264',
  },
  {
    name: 'ReceiptCity',
    value: 'CHARLOTTE',
  },
  {
    name: 'bShowAndPrintLogo',
    value: '1',
  },
  {
    name: 'PrintLogoWidth',
    value: '250',
  },
  {
    name: 'PrintLogoHeight',
    value: '250',
  },
  {
    name: 'PrintLogoAlign',
    value: 'Center',
  },
  {
    name: 'RxBin-Bulk',
    value: '1',
  },
  {
    name: 'RxBin-Refrigerator',
    value: '1',
  },
  {
    name: 'RxBin-A',
    value: '1',
  },
  {
    name: 'ReceiptLogo',
    value: 'logo.bmp',
  },
  {
    name: 'ReceiptAddress2',
    value: 'STE 135',
  },
  {
    name: 'ReceiptAddress1',
    value: '8514 MCALPINE PARK DR',
  },
  {
    name: 'ReceiptName',
    value: '',
  },
  {
    name: 'PrintDeliveryNotes',
    value: '0',
  },
  {
    name: 'DeliveryReceiptStyle',
    value: 'Standard',
  },
  {
    name: 'ReceiptMessage1',
    value: 'Thank you for testing with us.~True',
  },
  {
    name: 'ReceiptMessage2',
    value: 'We appreciate the bugs you find.~True',
  },
  {
    name: 'ReceiptMessage3',
    value: '* * * * * *~True',
  },
  {
    name: 'ReceiptMessage4',
    value: 'Please retain receipt for refund~True',
  },
  {
    name: 'ReceiptMessage5',
    value: '* * * * * *~True',
  },
  {
    name: 'ReceiptMessage6',
    value: 'Hours Open~True',
  },
  {
    name: 'ReceiptMessage7',
    value: 'Monday - Friday: 7 AM - 7 PM~True',
  },
  {
    name: 'ReceiptMessage8',
    value: 'Saturdays: 9 AM - 5 PM~True',
  },
  {
    name: 'ReceiptMessage9',
    value: 'Closed Sundays~True',
  },
  {
    name: 'DeliveryReceiptMessage',
    value: 'For Delivery Purposes Only',
  },
  {
    name: 'MailOutReceiptMessage1',
    value: 'Thank you for using Mail Out services~True',
  },
  {
    name: 'MailOutReceiptMessage2',
    value: 'We appreciate your business~True',
  },
  {
    name: 'MailOutReceiptMessage3',
    value: 'Please retain receipt for refund~True',
  },
  {
    name: 'RxBin-B',
    value: '1',
  },
  {
    name: 'RxBin-C',
    value: '1',
  },
  {
    name: 'RxBin-D',
    value: '1',
  },
  {
    name: 'RxBin-E',
    value: '1',
  },
  {
    name: 'RxBin-Y',
    value: '1',
  },
  {
    name: 'RxBin-Z',
    value: '1',
  },
  {
    name: 'RxBin-MedsToBed',
    value: '1',
  },
  {
    name: 'RxBin-Transplant',
    value: '1',
  },
  {
    name: 'GiftCardMaxValue',
    value: '0',
  },
  {
    name: 'ZeroGiftCard',
    value: '0',
  },
  {
    name: 'EnablePrescriptionTaxes',
    value: '1',
  },
  {
    name: 'FrankBankAccountNumber',
    value: '',
  },
  {
    name: 'FrankBankName',
    value: '',
  },
  {
    name: 'CheckFrankingEndorsementText',
    value: '',
  },
  {
    name: 'CheckFrankingRequireIDIsEnabled',
    value: '0',
  },
  {
    name: 'RxMedicBaseStationList',
    value: '',
  },
  {
    name: 'NoSafetyCapEnabled',
    value: '1',
  },
  {
    name: 'NoSafetyCapMessage',
    value: 'By my signature, I acknowledge my preference for No Safety Caps.',
  },
  {
    name: 'PI_Indicator',
    value: '0',
  },
  {
    name: 'RxBin-X',
    value: '1',
  },
  {
    name: 'ReceiptEmployeeStyle',
    value: 'Initials',
  },
  {
    name: 'RxBin-W',
    value: '1',
  },
  {
    name: 'RxBin-U',
    value: '1',
  },
  {
    name: 'RxBin-F',
    value: '1',
  },
  {
    name: 'RxBin-G',
    value: '1',
  },
  {
    name: 'RxBin-H',
    value: '1',
  },
  {
    name: 'RxBin-I',
    value: '1',
  },
  {
    name: 'RxBin-J',
    value: '1',
  },
  {
    name: 'RxBin-K',
    value: '1',
  },
  {
    name: 'RxBin-L',
    value: '1',
  },
  {
    name: 'RxBin-M',
    value: '1',
  },
  {
    name: 'RxBin-N',
    value: '1',
  },
  {
    name: 'RxBin-O',
    value: '1',
  },
  {
    name: 'RxBin-P',
    value: '1',
  },
  {
    name: 'RxBin-Q',
    value: '1',
  },
  {
    name: 'RxBin-R',
    value: '1',
  },
  {
    name: 'RxBin-S',
    value: '1',
  },
  {
    name: 'RxBin-T',
    value: '1',
  },
  {
    name: 'RxBin-V',
    value: '1',
  },
  {
    name: 'PrintSummaryCreditCardReceipt',
    value: '1',
  },
  {
    name: 'DiscountPercentage1',
    value: '10',
  },
  {
    name: 'DiscountReason1',
    value: '11',
  },
  {
    name: 'DiscountPercentage2',
    value: '15',
  },
  {
    name: 'DiscountReason2',
    value: '12',
  },
  {
    name: 'DiscountPercentage3',
    value: '20',
  },
  {
    name: 'DiscountReason3',
    value: '13',
  },
  {
    name: 'DiscountTransaction',
    value: '1',
  },
  {
    name: 'EmployeeDiscountReason',
    value: '11',
  },
  {
    name: 'TypeofServer',
    value: 'Corp/Store Server',
  },
  {
    name: 'TypeofSystem',
    value: 'Central Office\\ISBO',
  },
  {
    name: 'TIMEZONE',
    value: 'America/New_York',
  },
  {
    name: 'AgeRestrictedItemQuickEntryButtonsEnabled',
    value: '1',
  },
  {
    name: 'SudafedMessage',
    value:
      'By my signature, I acknowledge my understanding and compliance with state regulations limiting the purchase of products containing pseudoephedrine.~True',
  },
  {
    name: 'NPLEXPharmacyID',
    value: 'EMP0003',
  },
  {
    name: 'PromptforPriceChangeReason',
    value: '1',
  },
  {
    name: 'DiscountPrescription',
    value: '0',
  },
  {
    name: 'DiscountSaleItems',
    value: '0',
  },
  {
    name: 'DiscountItem',
    value: '1',
  },
  {
    name: 'IncludeStartingCashInTotals',
    value: '0',
  },
  {
    name: 'NeverReopenCashDrawer',
    value: '1',
  },
  {
    name: 'PopCashDrawerAtOpening',
    value: '1',
  },
  {
    name: 'PopCashDrawerAtClosing',
    value: '1',
  },
  {
    name: 'PopCashDrawerWithNoSale',
    value: '1',
  },
  {
    name: 'PopCashDrawerWithTender',
    value: 'Cash,Check',
  },
  {
    name: 'PromptWhenDrawerDateDifferent',
    value: '1',
  },
  {
    name: 'RequireDrawerEmployeeToUnlockStation',
    value: '0',
  },
  {
    name: 'AllowMixedTendersOnAR',
    value: '1',
  },
  {
    name: 'ARMessage',
    value:
      'By my signature, I acknowledge receipt of these goods and agree to pay [tender_amount] according to the AR payment policy.',
  },
  {
    name: 'PDMessage',
    value:
      'By my signature, I acknowledge receipt of these goods and agree to pay [tender_amount] according to the Payroll Deduction policy.',
  },
  {
    name: 'ARAccountTypeBySite',
    value: '1|Balance Forward',
  },
  {
    name: 'DefaultTaxGroup',
    value: '1000007',
  },
  {
    name: 'ShippingChargeTaxDefault',
    value: '1',
  },
  {
    name: 'DiscountMaximum',
    value: '20',
  },
  {
    name: 'NPLEXDenialReceiptMessage',
    value:
      'To inquire about this purchase attempt, visit the following website: https://www.nplexanswers.com\r\nYou will need the following Transaction ID: [nplex_transaction_id]',
  },
  {
    name: 'PAYMENT_GATEWAY_INTERFACE',
    value: 'element',
  },
  {
    name: 'PAYMENT_GATEWAY_TYPE',
    value: 'prod',
  },
  {
    name: 'GatewayUserName',
    value: '1013996',
  },
  {
    name: 'AllowAddCustomerToWillCall',
    value: '1',
  },
  {
    name: 'RetrievePrescriptionsFromAllSites',
    value: '1',
  },
  {
    name: 'RXMessage',
    value: 'By my signature, I acknowledge receipt of prescriptions.',
  },
  {
    name: 'CounselingEnabled',
    value: '1',
  },
  {
    name: 'CounselingAutoRequestEnabled',
    value: '0',
  },
  {
    name: 'NoCounselingPromptOnRxRefills',
    value: '1',
  },
  {
    name: 'CounselByRx',
    value: '1',
  },
  {
    name: 'ButtonLaterVisibility',
    value: '0',
  },
  {
    name: 'CounselingLine1',
    value: 'Do you have any questions about your prescription(s) today?~True',
  },
  {
    name: 'HIPAAEnabled',
    value: '1',
  },
  {
    name: 'HIPAARelationshipCustomEntryEnabled',
    value: '1',
  },
  {
    name: 'HIPAARelationshipPromptEnabled',
    value: '1',
  },
  {
    name: 'HIPAALine1',
    value: 'Would you like a copy of our HIPAA Privacy Policy?~True',
  },
  {
    name: 'PromptforReceipt',
    value: '0',
  },
  {
    name: 'PrintCustomerAddressEnabled',
    value: '0',
  },
  {
    name: 'RxRelationshipPromptEnabled',
    value: '0',
  },
  {
    name: 'PrintMerchantLogoOnReceiptEnabled',
    value: '1',
  },
  {
    name: 'CapturePrescriptionSignature',
    value: '1',
  },
  {
    name: 'ActionForSellingDeletedScripts',
    value: '0',
  },
  {
    name: 'GatewayPassword',
    value:
      '5CB66C981D29AE21515F8B168F3E0EF95C85142A4906799E4ED69E027709AB19D8581701',
  },
  {
    name: 'GatewayID',
    value: '874767034',
  },
  {
    name: 'GatewayTerminalID',
    value: '0001',
  },
  {
    name: 'GatewayDbaName',
    value: '',
  },
  {
    name: 'PAYMENT_GATEWAY_PARTNERID',
    value: '5238',
  },
  {
    name: 'PAYMENT_GATEWAY_PROCESSOR',
    value: '',
  },
  {
    name: 'CreditCardOnFileEnabled',
    value: '1',
  },
  {
    name: 'AllowCCOnFileSaveDuringPayment',
    value: '1',
  },
  {
    name: 'FSATenderEnabled',
    value: '1',
  },
  {
    name: 'FSAPartialApprovalEnabled',
    value: '1',
  },
  {
    name: 'CCMessage',
    value:
      'By my signature, I acknowledge receipt of these goods and agree to charge [tender_amount] to my credit card.',
  },
  {
    name: 'CashBackLimit',
    value: '100.00',
  },
  {
    name: 'DefaultToAmountDue',
    value:
      'Amex|credit,Check,Corporate Check,Coupon,DCCB|credit,Disc|credit,FSA|credit,AR,MC|credit,OfflineCCAuthorization,Payroll Deduct,User1,User2,User3,User4,User5,User6,Visa|credit',
  },
  {
    name: 'ThirdPartySoftware',
    value: '',
  },
  {
    name: 'DoNotDisplayDrugNamesOnSigCapDevice',
    value: '1',
  },
  {
    name: 'RxPickUpALLButtonWithSecurityEnabled',
    value: '1',
  },
  {
    name: 'DacEnabled',
    value: '1',
  },
  {
    name: 'DACTimeToLive',
    value: '480',
  },
  {
    name: 'AIR_DAILYSALES_EXPORT_ENABLED',
    value: '0',
  },
  {
    name: 'AIR_APIKEY',
    value: 'mys3cr3tk3y',
  },
  {
    name: 'PaymentDeviceOverride',
    value: '0',
  },
  {
    name: 'ManualEntryOverride',
    value: '1',
  },
  {
    name: 'PrintCustomerNameEnabled',
    value: '0',
  },
  {
    name: 'EmailReceiptOverride',
    value: '1',
  },
  {
    name: 'MCKESSON_COUNSELING_REQUIRED_ON_FIRST_FILLS',
    value: '0',
  },
  {
    name: 'triPOS_Cloud_Transaction_Processing_API',
    value: 'https://triposcert.vantiv.com/api/v1',
  },
  {
    name: 'triPOS_Cloud_Lane_Management_API',
    value: 'https://triposcert.vantiv.com/cloudapi/v1',
  },
  {
    name: 'CheckFrankingIsEnabled',
    value: '0',
  },
  {
    name: 'BackCheckFrankingIsEnabled',
    value: '0',
  },
  {
    name: 'OurServiceName',
    value: 'Emporos.DriveThru',
  },
  {
    name: 'OurServiceClassId',
    value: '29913A2D-2222-40cf-BBB8-DEEE26452197',
  },
  {
    name: 'DriveThruCommunicationProtocol',
    value: 'Bluetooth',
  },
  {
    name: 'DebugDeviceForm',
    value: '0',
  },
  {
    name: 'BlueToothMissing-Complete',
    value: '0',
  },
  {
    name: 'BlueToothMissing-CompleteOn16',
    value: '0',
  },
  {
    name: 'BlueToothMissing-CompleteOn14',
    value: '0',
  },
  {
    name: 'BlueToothCancel-Complete',
    value: '0',
  },
  {
    name: 'BlueToothCancel-CompleteOn16',
    value: '0',
  },
  {
    name: 'BlueToothCancel-CompleteOn14',
    value: '0',
  },
  {
    name: 'WifiServerURL',
    value: 'https://127.0.0.1',
  },
  {
    name: 'WifiServerPort',
    value: '27000',
  },
  {
    name: 'WifiClientURL',
    value: 'https://127.0.0.1',
  },
  {
    name: 'WifiClientPort',
    value: '27000',
  },
  {
    name: 'WifiServiceName',
    value: 'DriveThruIndigoService',
  },
  {
    name: 'MultiSlotsEnabled',
    value: '0',
  },
  {
    name: 'FrontCheckFrankingIsEnabled',
    value: '0',
  },
  {
    name: 'NumOfDrawers',
    value: '1',
  },
  {
    name: 'RxMedicLightMode',
    value: '',
  },
  {
    name: 'CayanDeviceMsgFormat',
    value: 'XML',
  },
  {
    name: 'DeviceToBypass',
    value: '-1',
  },
  {
    name: 'SigCapDisplayLogo',
    value: 'EmporosLogo.png',
  },
  {
    name: 'SigCapDisplayMessage',
    value: 'Welcome to the Twilight Zone...',
  },
  {
    name: 'ReceiptPrinterAdvanceLines',
    value: '3',
  },
  {
    name: 'PrintOPOS',
    value: '1',
  },
  {
    name: 'PrintLaser',
    value: '0',
  },
  {
    name: 'UserAuthentication',
    value: 'Session',
  },
  {
    name: 'RequireDrawerEmployeeIDForTransLogon',
    value: '0',
  },
  {
    name: 'DebugLog',
    value: '0',
  },
  {
    name: 'DefaultSchedulingTime',
    value: '300',
  },
  {
    name: 'CayanDeviceAddress',
    value: 'http://localhost:8080/api/v1',
  },
  {
    name: 'CayanDevicePort',
    value: '8080',
  },
  {
    name: 'CayanDeviceProxy',
    value: '0',
  },
  {
    name: 'CayanDeviceProxyName',
    value: '',
  },
  {
    name: 'CayanDeviceProxyPort',
    value: '80',
  },
  {
    name: 'CayanDeviceIsHTTPS',
    value: '',
  },
  {
    name: 'BluetoothPrinterEnabled',
    value: '0',
  },
  {
    name: 'BluetoothPrinterAddress',
    value: 'sa:m9:1e:ma:c6:16',
  },
  {
    name: 'AYS_Mobile_Till',
    value: '100.00',
  },
  {
    name: 'AYS_Available_Till',
    value: '',
  },
  {
    name: 'POSLayout',
    value: '4',
  },
  {
    name: 'PaymentTenderLayout',
    value: '6',
  },
  {
    name: 'ExpirationGracePeriod',
    value: '3',
  },
  {
    name: 'MaxAge',
    value: '90',
  },
  {
    name: 'WebServiceTimeoutKey',
    value: '33',
  },
  {
    name: 'PMSWebServiceTimeout',
    value: '22',
  },
  {
    name: 'RemoveableTillsEnabled',
    value: '1',
  },
  {
    name: 'PayrollDeductionEnabled',
    value: '1',
  },
  {
    name: 'LDAPEnabled',
    value: '0',
  },
  {
    name: 'LDAPHost',
    value: '',
  },
  {
    name: 'LDAPPort',
    value: '',
  },
  {
    name: 'LDAPUserName',
    value: '',
  },
  {
    name: 'LDAPPassword',
    value: '',
  },
  {
    name: 'LDAPBaseDN',
    value: '',
  },
  {
    name: 'LDAPDomainGroup',
    value: '',
  },
  {
    name: 'LDAPAccountCanonicalForm',
    value: '',
  },
  {
    name: 'LDAPAccountDomainName',
    value: '',
  },
  {
    name: 'LDAPAccountDomainNameShort',
    value: '',
  },
  {
    name: 'LDAPUserNameAttribute',
    value: '',
  },
  {
    name: 'IsSecureLDAPSupported',
    value: '',
  },
  {
    name: 'OIDC_Authority',
    value:
      'https://login.microsoftonline.com/9ee28ae6-e282-414c-b26b-6f910634d7e0/v2.0',
  },
  {
    name: 'OIDC_ClaimAttribute',
    value: 'email',
  },
  {
    name: 'OIDC_ClientId',
    value: '66ed59c6-8e1d-478f-be2e-01224242db8a',
  },
  {
    name: 'OIDC_Enabled',
    value: 'False',
  },
  {
    name: 'OIDC_RedirectUri',
    value: '',
  },
  {
    name: 'OIDC_Scope',
    value: 'openid profile email',
  },
  {
    name: 'OIDC_EmporosAttribute',
    value: 'Email',
  },
  {
    name: 'OIDC_AysClientId',
    value: '',
  },
  {
    name: 'OIDC_AysRedirectUri',
    value: '',
  },
  {
    name: 'OIDC_PosClientId',
    value: '66ed59c6-8e1d-478f-be2e-01224242db8a',
  },
  {
    name: 'OIDC_PosRedirectUri',
    value: 'http://127.0.0.1/emporos-pos',
  },
  {
    name: 'OIDC_EnterpriseSecret',
    value: '',
  },
  {
    name: 'ARCreditLimit',
    value: '250.0000',
  },
];

export const createTaxGroup = (props?: Partial<TaxGroup>): TaxGroup => ({
  id: 1000000,
  taxRateId: 1000002,
  taxRateDescription: 'Mecklenburg County',
  taxRate: 0.5,
  ...props,
});

export const createTaxGroups = (): TaxGroup[] => [
  {
    id: 1000000,
    taxRateId: 1000002,
    taxRateDescription: 'Mecklenburg County',
    taxRate: 0.5,
  },
  {
    id: 1000001,
    taxRateId: 1000001,
    taxRateDescription: 'NC County Tax',
    taxRate: 2,
  },
  {
    id: 1000001,
    taxRateId: 1000000,
    taxRateDescription: 'NC State Tax',
    taxRate: 4.75,
  },
  {
    id: 1000000,
    taxRateId: 1000001,
    taxRateDescription: 'NC County Tax',
    taxRate: 2,
  },
  {
    id: 1000000,
    taxRateId: 1000000,
    taxRateDescription: 'NC State Tax',
    taxRate: 4.75,
  },
  {
    id: 1000007,
    taxRateId: 1000003,
    taxRateDescription: 'FL State Tax',
    taxRate: 6.5,
  },
  {
    id: 1000003,
    taxRateId: 1000004,
    taxRateDescription: 'MA State Sales Tax',
    taxRate: 5,
  },
  {
    id: 1000004,
    taxRateId: 1000005,
    taxRateDescription: 'Food and Beverage',
    taxRate: 0,
  },
  {
    id: 1000005,
    taxRateId: 1000005,
    taxRateDescription: 'Food and Beverage',
    taxRate: 0,
  },
  {
    id: 1000006,
    taxRateId: 1000006,
    taxRateDescription: 'High Tax',
    taxRate: 99,
  },
  {
    id: 1000002,
    taxRateId: 1000003,
    taxRateDescription: 'FL State Tax',
    taxRate: 6.5,
  },
  {
    id: 1000002,
    taxRateId: 1000005,
    taxRateDescription: 'Food and Beverage',
    taxRate: 0,
  },
];

export const createTransactionPayment = (
  props?: Partial<TransactionPayment>,
): TransactionPayment => ({
  transactionPaymentId: '',
  transactionId: '',
  amount: 0,
  changeIndicator: false,
  qhpPartial: false,
  fsaBalanceAmount: 0,
  voiceAuthIndicator: false,
  autoChargeIndicator: false,
  sigCapSentToProcessor: false,
  createdOn: new Date(),
  recordStatus: 'Active',
  ...props,
});

export const createSetting = (props: Partial<Setting>): Setting => ({
  name: '',
  value: '',
  ...props,
});

const createPhoneHelper = (props?: Partial<Phone | CustomerPhone>) => ({
  number: '',
  capabilities: 0,
  isPrimary: false,
  type: '',
  ...props,
});

export const createCustomerPhone = (props?: Partial<CustomerPhone>): Phone =>
  createPhoneHelper(props);

export const createPhone = (props?: Partial<Phone>): Phone =>
  createPhoneHelper(props);

export const createRelationship = (
  props?: Partial<Relationship>,
): Relationship => ({
  id: 0,
  name: '',
  ...props,
});

export const createRelationships = (): Relationship[] => [
  createRelationship({name: 'Self', id: 1}),
  createRelationship({
    name: 'Spouse',
    id: 2,
  }),
  createRelationship({
    name: 'Parent',
    id: 3,
  }),
  createRelationship({
    name: 'Child',
    id: 4,
  }),
  createRelationship({
    name: 'POA',
    id: 5,
  }),
  createRelationship({
    name: 'Guardian',
    id: 6,
  }),
  createRelationship({
    name: 'Caregiver',
    id: 7,
  }),
  createRelationship({
    name: 'Other',
    id: 8,
  }),
];

const customerObj = {
  id: 0,
  firstName: 'John',
  lastName: 'Doe',
  phones: [],
  notes: [],
  accounts: [],
  creditCards: [],
  showHIPAA: false,
};

const customerObjAccount = {
  id: 0,
  firstName: 'John',
  lastName: 'Doe',
  phones: [],
  notes: [],
  accounts: [createCustomerAccount({balance: 10, creditHoldIndicator: true})],
  creditCards: [],
  showHIPAA: false,
};

const customerObjAccountWithHoldIndicator_True = {
  id: 0,
  firstName: 'John',
  lastName: 'Doe',
  phones: [],
  notes: [],
  accounts: [createCustomerAccount({balance: 10, creditHoldIndicator: false})],
  creditCards: [],
  showHIPAA: false,
  creditHoldIndicator: true,
};

export const createCustomer = (props?: Partial<Customer>): Customer => ({
  ...customerObj,
  ...props,
});

export const createCustomer_creditHoldIndicatorTrue = (
  props?: Partial<Customer>,
): Customer => ({
  ...customerObjAccountWithHoldIndicator_True,
  ...props,
});

export const createCustomerWithAccount_creditHoldIndicatorTrue = (
  props?: Partial<Customer>,
): Customer => ({
  ...customerObjAccount,
  ...props,
});

export const createTransactionCustomer = (
  props?: Partial<SessionCustomer>,
): SessionCustomer => ({
  ...customerObj,
  ...props,
});

export const createCustomerSearchMatch = (
  props?: Partial<CustomerSearchMatch>,
): CustomerSearchMatch => ({
  id: 0,
  firstName: 'John',
  lastName: 'Doe',
  customerCode: '123456',
  mrn: '654321',
  ...props,
});

export const createCustomerResponse = (
  props?: Partial<CustomerResponse>,
): CustomerResponse => ({
  id: 0,
  showHIPAA: false,
  phones: [],
  notes: [],
  creditCards: [],
  firstName: 'Robert',
  lastName: 'Turner',
  accounts: [],
  ...props,
});

export const createCustomerAccountResponse = (
  props?: Partial<CustomerAccountResponse>,
): CustomerAccountResponse => ({
  accountId: 0,
  accountNumber: '0',
  accountTypeId: 0,
  accountType: '',
  balance: 0,
  balanceDate: null,
  creditHoldIndicator: false,
  creditLimit: 0,
  currentBalance: 0,
  over90Balance: 0,
  past30Balance: 0,
  past60Balance: 0,
  isPrimary: false,
  createDate: new Date(),
  recordStatus: 'Active',
  ...props,
});

export const createBarcodes = (): BarcodeCollection => ({
  barcodeComponents: [
    {
      id: 1,
      barcodeName: 'EmployeeBadge',
      componentName: 'BadgeNumber',
      componentMask: '^[vV]{0,1}[0-9]{5}$',
      componentIndex: 1,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: false,
    },
    {
      id: 2,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Address1',
      componentMask: '^[\\w\\W\\s]{0,100}$',
      componentIndex: 8,
      allowManualEntry: true,
      manualEntryDisplayText: 'Address1',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 3,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Address2',
      componentMask: '^[\\w\\W\\s]{0,100}$',
      componentIndex: 9,
      allowManualEntry: true,
      manualEntryDisplayText: 'Address2',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 4,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Birthdate',
      componentMask: '^[0-9/]{0,10}$',
      componentIndex: 4,
      allowManualEntry: true,
      manualEntryDisplayText: 'Birthdate',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 5,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'City',
      componentMask: '^[\\w\\s]{0,100}$',
      componentIndex: 10,
      allowManualEntry: true,
      manualEntryDisplayText: 'City',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 6,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'delimiter',
      componentMask: '^',
      componentIndex: 0,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 7,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'DLnumber',
      componentMask: '^[\\w-\\s]{0,100}$',
      componentIndex: 2,
      allowManualEntry: true,
      manualEntryDisplayText: 'DLnumber',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 8,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'ExpirationDate',
      componentMask: '^[0-9/]{0,10}$',
      componentIndex: 12,
      allowManualEntry: true,
      manualEntryDisplayText: 'ExpirationDate',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 9,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'FirstName',
      componentMask: '^[\\w\\W\\s]{0,50}$',
      componentIndex: 5,
      allowManualEntry: true,
      manualEntryDisplayText: 'FirstName',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 10,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Gender',
      componentMask: '^[\\w\\s]{0,10}$',
      componentIndex: 14,
      allowManualEntry: true,
      manualEntryDisplayText: 'Gender',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 11,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Height',
      componentMask: '^[\\w\\s-]{0,10}$',
      componentIndex: 15,
      allowManualEntry: true,
      manualEntryDisplayText: 'Height',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 12,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'IssueDate',
      componentMask: '^[0-9/]{0,10}$',
      componentIndex: 13,
      allowManualEntry: true,
      manualEntryDisplayText: 'IssueDate',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 13,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'LastName',
      componentMask: '^[\\w\\W\\s]{0,50}$',
      componentIndex: 7,
      allowManualEntry: true,
      manualEntryDisplayText: 'LastName',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 14,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'MiddleName',
      componentMask: '^[\\w\\W\\s]{0,50}$',
      componentIndex: 6,
      allowManualEntry: true,
      manualEntryDisplayText: 'MiddleName',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 15,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'prefix',
      componentMask: '^[$]{1}$',
      componentIndex: 1,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 16,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'State',
      componentMask: '^[\\w\\s]{0,2}$',
      componentIndex: 3,
      allowManualEntry: true,
      manualEntryDisplayText: 'State',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 17,
      barcodeName: 'HoneywellXenon1900DriverLicense',
      componentName: 'Zipcode',
      componentMask: '^[\\w\\s-]{0,10}$',
      componentIndex: 11,
      allowManualEntry: true,
      manualEntryDisplayText: 'Zipcode',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 18,
      barcodeName: 'SalesReceipt',
      componentName: 'ReceiptNumber',
      componentMask: '^[0-9]{4}[-]{1}[0-9]{6}$',
      componentIndex: 1,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: false,
    },
    {
      id: 19,
      barcodeName: 'Standard1DEmporos',
      componentName: 'Amount',
      componentMask: '^[0-9]{7}$',
      componentIndex: -1,
      allowManualEntry: true,
      manualEntryDisplayText: 'Price',
      manualEntryDisplayIndex: 4,
      required: true,
    },
    {
      id: 20,
      barcodeName: 'Standard1DEmporos',
      componentName: 'partial',
      componentMask: '^[0-9]{1}$',
      componentIndex: 5,
      allowManualEntry: true,
      manualEntryDisplayText: 'Partial Number',
      manualEntryDisplayIndex: 3,
      required: true,
    },
    {
      id: 21,
      barcodeName: 'Standard1DEmporos',
      componentName: 'prefix',
      componentMask: '^4$',
      componentIndex: 1,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 22,
      barcodeName: 'Standard1DEmporos',
      componentName: 'refillnumber',
      componentMask: '^[0-9]{2}$',
      componentIndex: 4,
      allowManualEntry: true,
      manualEntryDisplayText: 'Refill Number',
      manualEntryDisplayIndex: 2,
      required: true,
    },
    {
      id: 23,
      barcodeName: 'Standard1DEmporos',
      componentName: 'rxnumber',
      componentMask: '^[0-9]{7}$',
      componentIndex: 3,
      allowManualEntry: true,
      manualEntryDisplayText: 'Rx Number',
      manualEntryDisplayIndex: 1,
      required: true,
    },
    {
      id: 24,
      barcodeName: 'Standard1DEmporos',
      componentName: 'store',
      componentMask: '^[0-9]{3}$',
      componentIndex: 2,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: false,
    },
    {
      id: 25,
      barcodeName: 'VialScan',
      componentName: 'prefix',
      componentMask: '^[RXN]{3}$',
      componentIndex: 1,
      allowManualEntry: false,
      manualEntryDisplayText: '',
      manualEntryDisplayIndex: 0,
      required: true,
    },
    {
      id: 26,
      barcodeName: 'VialScan',
      componentName: 'refillnumber',
      componentMask: '^[0-9]{5}$',
      componentIndex: 3,
      allowManualEntry: true,
      manualEntryDisplayText: 'Refill Number',
      manualEntryDisplayIndex: 2,
      required: true,
    },
    {
      id: 27,
      barcodeName: 'VialScan',
      componentName: 'rxnumber',
      componentMask: '^[0-9A-Z-]{6}$',
      componentIndex: 2,
      allowManualEntry: true,
      manualEntryDisplayText: 'Rx Number',
      manualEntryDisplayIndex: 1,
      required: true,
    },
  ],
  pmsBarcodeMappings: [
    {
      pmsName: 'MerchantSoft',
      barcodeName: '',
    },
    {
      pmsName: 'McKesson',
      barcodeName: 'Standard1DEmporos',
    },
  ],
});

export const createTransactionCustomerAccount = (
  props?: Partial<CustomerAccount>,
): CustomerAccount => ({
  accountId: 0,
  accountNumber: '',
  accountTypeId: 0,
  accountType: '',
  customerId: 0,
  balance: 0,
  creditHoldIndicator: false,
  creditLimit: 0,
  currentBalance: 0,
  over90Balance: 0,
  past30Balance: 0,
  past60Balance: 0,
  isPrimary: false,
  createDate: new Date(),
  recordStatus: 'Active',
  ...props,
});

export function createCustomerAccount(
  partial: Partial<CustomerAccount> = {},
): CustomerAccount {
  return {
    accountId: 0,
    accountType: '',
    accountNumber: '0',
    accountTypeId: 0,
    balance: 0,
    balanceDate: null,
    creditHoldIndicator: false,
    creditLimit: 0,
    currentBalance: 0,
    customerId: 0,
    over90Balance: 0,
    past30Balance: 0,
    past60Balance: 0,
    isPrimary: false,
    createDate: new Date(),
    recordStatus: 'Active',
    ...partial,
  };
}

export const createCustomerCreditCard = (
  props?: Partial<CustomerCreditCard>,
): CustomerCreditCard => ({
  paymentInformationId: 0,
  cardType: '',
  member: '',
  expiration: new Date(),
  isPrimary: false,
  paymentTypeId: 0,
  last4OfCard: '',
  token: '',
  ...props,
});

export const createSession = (props?: Partial<Session>): Session => ({
  sessionId: '',
  userId: 0,
  drawerTransactionId: 0,
  transactions: [],
  siteId: 0,
  stationId: 0,
  tillId: 0,
  paymentDeviceAddress: null,
  paymentDevicePort: null,
  startingCashBalance: 0,
  hostedPaymentsEnabled: false,
  drawerDate: new Date(),
  till: {} as CashDrawer,
  station: {} as Station,
  site: {} as Site,
  status: SessionStatusEnum.NUMBER_0,
  accessCode: '123456',
  deviceId: 'deviceID',
  ...props,
});

export const createSessionHostedPaymentEnabled = (
  props?: Partial<Session>,
): Session => ({
  sessionId: '',
  userId: 0,
  drawerTransactionId: 0,
  transactions: [],
  siteId: 0,
  stationId: 0,
  tillId: 0,
  paymentDeviceAddress: null,
  paymentDevicePort: null,
  startingCashBalance: 0,
  hostedPaymentsEnabled: true,
  drawerDate: new Date(),
  till: {} as CashDrawer,
  station: {} as Station,
  site: {} as Site,
  status: SessionStatusEnum.NUMBER_0,
  accessCode: '123456',
  deviceId: 'deviceID',
  ...props,
});

export const createTransactionIdentification = (
  props?: Partial<TransactionIdentification>,
): TransactionIdentification => ({
  transactionIdentificationId: '',
  transactionId: '',
  idTypeID: 0,
  ...props,
});

export const createTransactionSignature = (
  props?: Partial<TransactionSignature>,
): TransactionSignature => ({
  signatureImage: {
    imageData: '',
    pointData: [],
    signatureImageId: '0',
    serverSignatureId: 0,
    signatureImageType: 'points',
    dataVersion: '',
  },
  transactionSignatureId: '',
  transactionId: '',
  signatureTypeId: 0,
  signatureType: {
    signatureTypeId: 0,
  },
  createdOn: new Date(),
  ...props,
});

export const createTransactionExtra = (
  props?: Partial<TransactionExtra>,
): TransactionExtra => ({
  rowId: '',
  transactionId: '',
  ...props,
});

export const createSignatureImage = (
  props?: Partial<SignatureImage>,
): SignatureImage => ({
  imageData: '',
  pointData: [],
  signatureImageId: '',
  serverSignatureId: 0,
  signatureImageType: '',
  ...props,
});
