import {Transaction, OtcItem, Prescription} from '@emporos/api-enterprise';
import {
  CompleteBarcodeComponent,
  ScanResult,
  usePMSStrategy,
} from '@emporos/barcodes';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  PendingChangesModal,
  Size,
  Stack,
  Text,
  TextVariant as Variant,
  Variant as BV,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import {useCallback, useEffect, useState} from 'react';
import {
  AlertLogTypes,
  AnalyticType,
  determineLoadingStatus,
  ITEM_TYPE_IDS,
  OfflineTransaction,
  OtcSearch,
  useAlertState,
  useAnalyticsProvider,
  useLog,
  UserLogTypes,
  useSessionData,
} from '../../../../';

export type AddItemsResult = {otc?: OtcItem[]; rx?: Prescription[]};

interface Props {
  onAdd: (result: AddItemsResult) => void;
  barcodeComponents: CompleteBarcodeComponent[];
  currentTransactionId: Transaction['transactionId'];
  transaction: OfflineTransaction;
  loading?: boolean;
  onCancel: () => void;
  online?: boolean;
  otcItems?: OtcItem[];
  pmsName: string;
  title: string;
  transactionChanged: boolean;
}

const itemsLoadedStyle = {
  fontSize: '13px',
  marginTop: '14px',
};

export function OtcItemsPanel({
  barcodeComponents,
  transaction,
  transactionChanged,
  loading,
  online,
  otcItems,
  title,
  onAdd,
  onCancel,
}: Props): JSX.Element {
  const {notification, reset} = useAlertState();
  const {track} = useAnalyticsProvider();
  const {logAlert, logUserSelection} = useLog();
  const {otcItemsResult, syncOTC} = useSessionData();
  const [selectedOtcItems, setSelectedOtcItems] = useState<Array<OtcItem>>([]);
  const [scrollIndex, setScrollIndex] = useState<number>();
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const customer = transaction?.customer;
  const [syncOtcDisabled, setSyncOtcDisabled] = useState<boolean>(false);
  const [totalItemCount, setTotalItemCount] = useState<number>();
  const {strategy: pmsStrategy} = usePMSStrategy();

  useEffect(() => {
    if (!otcItemsResult) return;
    const {fatalError, loading, pagination, syncPagination, syncLoading} =
      otcItemsResult;
    setSyncOtcDisabled(
      determineLoadingStatus(loading, syncLoading, fatalError),
    );
    if (!pagination) return;
    if (syncPagination) {
      setTotalItemCount(pagination.count + syncPagination.count);
    } else {
      setTotalItemCount(pagination.count);
    }
  }, [otcItemsResult]);

  const addItems = useCallback(() => {
    onAdd({
      otc: selectedOtcItems,
    });
  }, [customer, selectedOtcItems]);

  const canSave = selectedOtcItems.length;
  const cancelWithModal = customer && transactionChanged;

  const _onCancel = useCallback(() => {
    if (cancelWithModal) {
      logAlert(AlertLogTypes.PendingChanges, {
        location: 'OtcItemsPanel',
      });
      setShowPendingChangesModal(true);
    } else {
      onCancel();
    }
  }, [onCancel, customer]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setShowPendingChangesModal(false);
    addItems();
  }

  function onSync(e: React.FormEvent) {
    e.preventDefault();
    syncOTC();
  }

  const handleOTCScan = useCallback(
    (scanResult: string) => {
      if (!otcItems) {
        return;
      }
      const otcItem =
        otcItems && otcItems.find(item => item.itemNumber === scanResult);

      if (otcItem) {
        setScrollIndex(
          otcItems.findIndex(
            ({itemNumber}) => itemNumber === otcItem.itemNumber,
          ),
        );
        if (
          otcItem.itemType.id === ITEM_TYPE_IDS.METH_FREE &&
          transaction.pseCheckResult === 1
        ) {
          notification({
            title: 'PSE Items Locked',
            description:
              'The user has already passed the NPLEx check and these items cannot be edited.',
            type: 'warning',
            icon: 'Warning',
          });
        } else {
          setSelectedOtcItems(selectedRx => [...selectedRx, otcItem]);
        }
        logUserSelection(UserLogTypes.ScannedOTC, {
          result: otcItem.description,
        });
      } else {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned OTC item couldn’t be found in the database.',
        });
      }
    },
    [otcItems],
  );

  const onScan = useCallback(
    (scanResult: ScanResult) => {
      const item = pmsStrategy.decode(scanResult, barcodeComponents);
      reset();

      if (item.rx && item.partial && item.fill && item.site) {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned RX item in OTC search.',
        });
      } else {
        handleOTCScan(scanResult.raw);
      }
    },
    [barcodeComponents, pmsStrategy, handleOTCScan],
  );

  return (
    <>
      <Stack
        gutter={Gutter.XL}
        justify="stretch"
        style={{
          height:
            innerWidth > 1180
              ? 'calc(var(--vh, 1vh) * 100 - 310px)'
              : 'calc(var(--vh, 1vh) * 100 - 268px)',
        }}
        as="form"
        onSubmit={onSubmit}
      >
        <Header title={title}>
          <Button
            disabled={syncOtcDisabled}
            icon="Refresh"
            size={Size.Small}
            type="button"
            onClick={onSync}
            loading={loading}
          >
            Sync
          </Button>
        </Header>
        {otcItemsResult && (
          <Text
            variant={Variant.Body}
            intent={
              !otcItemsResult.loading &&
              !otcItemsResult.syncLoading &&
              otcItemsResult.data.length !== totalItemCount
                ? 'Alert'
                : undefined
            }
            data-testid={'otcPanel-itemsLoadedCount'}
            style={itemsLoadedStyle}
          >
            Items Loaded: {otcItemsResult.data.length} / {totalItemCount}
          </Text>
        )}
        <OtcSearch
          lockPseItems={transaction.pseCheckResult === 1}
          otcItems={otcItems}
          scrollToIndex={scrollIndex}
          selectedOTCItems={selectedOtcItems}
          setSelectedOTCItems={setSelectedOtcItems}
        />

        <FooterGroup>
          <Button
            type="button"
            onClick={_onCancel}
            variant={cancelWithModal ? BV.Danger : BV.Secondary}
            flex
          >
            Cancel
          </Button>
          <Button
            variant={BV.Primary}
            type="submit"
            flex
            loading={loading}
            disabled={!canSave}
          >
            Add to Sale
          </Button>
        </FooterGroup>
      </Stack>
      {online && (
        <PageBarcodeScanner onScan={onScan} closeAfterSuccess={false} />
      )}
      <PendingChangesModal
        visible={showPendingChangesModal}
        onCancel={() => setShowPendingChangesModal(false)}
        onContinue={onCancel}
      />
    </>
  );
}
