import {
  ApiResponse,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError,
  TransactionPayment,
  TransactionPaymentFromJSON,
  TransactionPaymentRequest,
} from '@emporos/api-enterprise';
import {encryptionMiddlewareFunction} from '../utils/crypto';
import {EncryptedBaseApi} from './EncryptedBaseApi';

export class EncryptedTransactionPaymentsApi extends EncryptedBaseApi {
  /**
   * Inserts an transactionPayment
   */
  async InsertTransactionPaymentEncryptedRaw(
    sessionId: string,
    transactionPayment: TransactionPayment,
    secretByteArray: Uint8Array,
  ): Promise<ApiResponse<TransactionPayment>> {
    this.checkRequiredParameters(
      sessionId,
      transactionPayment.transactionId,
      secretByteArray,
      'EncryptedTransactionPaymentsApi.InsertTransactionPaymentEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments`
        .replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)))
        .replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(transactionPayment.transactionId)),
        ),
      this.generateEncryptedPayloadFromDecryptedPayment(
        transactionPayment,
        secretByteArray,
      ),
      headerParameters,
      queryParameters,
      'POST',
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionPaymentFromJSON(jsonValue),
    );
  }

  /**
   * Inserts an transactionPayment
   */
  async InsertTransactionPaymentEncrypted(
    sessionId: string,
    transactionPayment: TransactionPayment,
    secretByteArray: Uint8Array,
  ): Promise<TransactionPayment> {
    const response = await this.InsertTransactionPaymentEncryptedRaw(
      sessionId,
      transactionPayment,
      secretByteArray,
    );
    return await response.value();
  }

  /**
   * Updates an transactionPayment
   */
  async UpdateTransactionPaymentEncryptedRaw(
    sessionId: string,
    transactionPayment: TransactionPayment,
    secretByteArray: Uint8Array,
  ): Promise<ApiResponse<TransactionPayment>> {
    this.checkRequiredParameters(
      sessionId,
      transactionPayment.transactionId,
      secretByteArray,
      'EncryptedTransactionPaymentsApi.UpdateTransactionPaymentEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments`
        .replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)))
        .replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(transactionPayment.transactionId)),
        ),
      this.generateEncryptedPayloadFromDecryptedPayment(
        transactionPayment,
        secretByteArray,
      ),
      headerParameters,
      queryParameters,
      'PUT',
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionPaymentFromJSON(jsonValue),
    );
  }

  /**
   * Updates an transactionPayment
   */
  async UpdateTransactionPaymentEncrypted(
    sessionId: string,
    transactionPayment: TransactionPayment,
    secretByteArray: Uint8Array,
  ): Promise<TransactionPayment> {
    const response = await this.UpdateTransactionPaymentEncryptedRaw(
      sessionId,
      transactionPayment,
      secretByteArray,
    );
    return await response.value();
  }

  checkRequiredParameters(
    sessionId: string,
    transactionId: string,
    secretByteArray: Uint8Array,
    methodName: string,
  ): void {
    if (sessionId === null || sessionId === undefined) {
      throw new RequiredError(
        'sessionId',
        'Required parameter sessionId was null or undefined when calling ' +
          methodName,
      );
    }

    if (transactionId === null || transactionId === undefined) {
      throw new RequiredError(
        'transactionId',
        'Required parameter encryptedTransactionPayment.transactionId was null or undefined when calling ' +
          methodName,
      );
    }

    if (secretByteArray === null || secretByteArray === undefined) {
      throw new RequiredError(
        'secretByteArray',
        'Required parameter encryptedTransactionPayment.secretByteArray was null or undefined when calling ' +
          methodName,
      );
    }
  }

  generateEncryptedPayloadFromDecryptedPayment(
    transactionPayment: TransactionPayment,
    secret: Uint8Array,
  ): string {
    const encryptedPayload = encryptionMiddlewareFunction(
      secret,
      transactionPayment as TransactionPaymentRequest,
    );
    return this.ConvertToEncodedString(encryptedPayload);
  }
}
