import {
  Modal,
  Page,
  PageWrapper,
  PortraitNotSupported,
  SidebarWrapper,
} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {Redirect, RouteComponentProps, useLocation} from '@reach/router';
import {useEffect, useState} from 'react';
import * as React from 'react';
import * as reactSpring from 'react-spring';
import styled from 'styled-components';
import {
  calculateOtcPercentage,
  determineLoadingStatus,
  fatalOTCError,
  useSessionData,
} from './../';

import {
  AuthClaim,
  NavigationLogTypes,
  OTC_PAGE_SIZE,
  Sidebar,
  useAlertState,
  useAuthentication,
  useLog,
  useSyncSession,
  useTransactionsState,
} from '../';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

interface Props {
  children?: React.ReactElement | React.ReactElement[];
}

interface LoadingErrorModalProps {
  modalOpen: boolean;
  modalColor: 'error' | 'warning';
  modalDetails: string;
  errorProcess: 'download' | 'sync';
}

export function MainLayout({
  children,
}: RouteComponentProps & Props): JSX.Element {
  const {logout, user} = useAuthentication();
  const {logUserSelection} = useLog();
  const {reset} = useAlertState();
  const {syncSession, sessionStatus} = useSyncSession();
  const transition = reactSpring.useSpring({opacity: 1, from: {opacity: 0}});
  const {pathname} = useLocation();
  const {currentTransactionId, savingSession} = useTransactionsState();
  const {otcItemsResult, retryOTCLoad} = useSessionData();
  const [isLoadingOtc, setIsLoadingOtc] = useState(false);
  const [percentageOtcLoaded, setPercentageOtcLoaded] = useState(0);
  const [hasFatalOtcLoadingError, setHasFatalOtcLoadingError] =
    useState<fatalOTCError>({error: false, process: 'download'});
  const [modalProps, setModalProps] = useState<LoadingErrorModalProps>({
    modalOpen: false,
    modalColor: 'error',
    modalDetails: 'OTC Items unable to Load. Please Contact Support.',
    errorProcess: 'download',
  });

  useEffect(() => {
    if (!otcItemsResult) return;
    const {data, fatalError, loading, pagination, syncLoading, syncPagination} =
      otcItemsResult;
    setIsLoadingOtc(determineLoadingStatus(loading, syncLoading));
    if (pagination && pagination.pages > 0) {
      setPercentageOtcLoaded(
        calculateOtcPercentage(pagination.page, pagination.pages),
      );
    }
    if (syncPagination && syncPagination.pages > 0) {
      setPercentageOtcLoaded(
        calculateOtcPercentage(syncPagination.page, syncPagination.pages),
      );
    }
    if (fatalError) {
      setHasFatalOtcLoadingError(fatalError);
      if (!fatalError.error) return;
      switch (fatalError.process) {
        case 'download':
          if (data && data.length > 0) {
            setModalProps({
              modalOpen: true,
              modalDetails: `OTC Items unable to load. Last OTC Item ID was ${
                data[data.length - 1]?.id ?? '?'
              }, Page: ${
                pagination?.page ?? '?'
              }, Page Size: ${OTC_PAGE_SIZE}. Please Contact Support.`,
              modalColor: 'error',
              errorProcess: 'download',
            }); // Pagination info available - give details
          } else {
            setModalProps({
              modalOpen: true,
              modalDetails: 'OTC Items unable to Load. Please Contact Support.',
              modalColor: 'error',
              errorProcess: 'download',
            }); // No pagination info available
          }
          return;
        case 'sync':
          setModalProps({
            modalOpen: true,
            modalDetails: `There was an unexpected error when syncing OTC items. Re-sync, open a new session or contact support.`,
            modalColor: 'error',
            errorProcess: 'sync',
          }); // Pagination info not that meaningful with sync
          return;
      }
    }
  }, [otcItemsResult]);

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    logUserSelection(NavigationLogTypes.UserNavigating, {
      url: location.pathname,
    });
  }, [location.pathname]);

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  if (!currentTransactionId && /payments/.test(pathname)) {
    return <Redirect to="/sales" noThrow />;
  }
  return (
    <>
      <div className="layout-landscape">
        <Navbar
          username={(user?.profile[AuthClaim.Name] || '') as string}
          onSessionStatus={syncSession}
          sessionStatus={sessionStatus}
          onLogout={logout}
          isLoadingData={savingSession}
          hideSessionStatus={true}
          isLoadingOtc={isLoadingOtc}
          percentageOtcLoaded={percentageOtcLoaded}
          hasFatalOtcLoadingError={hasFatalOtcLoadingError.error}
          onLoadingOtcError={() =>
            setModalProps({
              ...modalProps,
              modalOpen: true,
            })
          }
        />
        <MainWrapper>
          <SidebarWrapper
            as={reactSpring.animated.div}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            style={transition as any}
          >
            <Sidebar />
          </SidebarWrapper>
          <PageWrapper style={{backgroundColor: 'transparent'}}>
            <Page open>{children}</Page>
          </PageWrapper>
          {modalProps.modalOpen && modalProps.errorProcess === 'download' && (
            <Modal
              buttonText="Retry"
              data-testid="hasLoadingError-modal"
              visible={modalProps.modalOpen}
              icon="Warning"
              color={modalProps.modalColor}
              onCancel={() => setModalProps({...modalProps, modalOpen: false})}
              onContinue={() => {
                retryOTCLoad();
                setHasFatalOtcLoadingError({error: false, process: 'download'});
                setModalProps({...modalProps, modalOpen: false});
              }}
              title="OTC Items Error"
              subtitle={modalProps.modalDetails}
            />
          )}
          {modalProps.modalOpen && modalProps.errorProcess === 'sync' && (
            <Modal
              buttonText="Continue"
              data-testid="hasSyncingError-modal"
              visible={modalProps.modalOpen}
              icon="Warning"
              color={modalProps.modalColor}
              onContinue={() =>
                setModalProps({...modalProps, modalOpen: false})
              }
              title="OTC Items Error"
              subtitle={modalProps.modalDetails}
            />
          )}
        </MainWrapper>
      </div>
      <div className="layout-portrait">
        <PortraitNotSupported></PortraitNotSupported>
      </div>
    </>
  );
}
