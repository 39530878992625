import {BarcodeCollection, Transaction} from '@emporos/api-enterprise';
import {
  CompleteBarcodeComponent,
  ScanResult,
  usePMSStrategy,
} from '@emporos/barcodes';
import {Redirect, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useMemo} from 'react';
import {
  AnalyticType,
  createTransactionOtcReducer,
  NavigationLogTypes,
  useAlertState,
  useAnalyticsProvider,
  useSessionData,
  useTransaction,
  useLog,
  useMultiSelect,
  UserLogTypes,
  useSession,
  useTotals,
  useTransactionsState,
  withRootPage,
  TransactionPage,
  ITEM_TYPE_IDS,
} from '../../../';

export interface ManualRXFormData {
  rx: string;
  fill: string;
  site: string;
  partial?: string;
  price?: string;
  rxId?: string;
  mrn?: string;
  plan1?: string;
  plan2?: string;
  plan3?: string;
  plan4?: string;
  plan5?: string;
}

const EMPTY_BARCODES: BarcodeCollection = {
  barcodeComponents: [],
  pmsBarcodeMappings: [],
};

function TransactionIntegrationComponent({
  navigate,
}: RouteComponentProps): JSX.Element | null {
  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<TransactionPage /> must have a `navigate` prop.' + String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );

  const {strategy: pmsStrategy} = usePMSStrategy();
  const {logUserSelection} = useLog();
  const {currentTransactionId, setCurrentTransactionId} =
    useTransactionsState();
  const {updateSession} = useSession();
  const {
    barcodeName,
    barcodesResult,
    pmsName,
    otcItemsResult,
    settingsResult,
    enabledFeatures,
  } = useSessionData();

  if (!currentTransactionId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const multiselect = useMultiSelect<string>();

  const {transaction, updateTransaction, updateTransactionItem, canDelete} =
    useTransaction();
  const {totals} = useTotals();

  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? (barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          ) as CompleteBarcodeComponent[])
        : [],
    [barcodesResult],
  );

  function onScan(scanResult: ScanResult) {
    if (!(pmsName && barcodesResult && navigate)) {
      return;
    }

    const item = pmsStrategy.decode(scanResult, barcodeComponents);
    const isRx = Boolean(item.rx && item.fill && item.site);

    if (isRx) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: 'sales/transactions/customer',
        info: 'Scanned RX item.',
      });
      navigate(`customer`, {
        state: item,
      });
    } else if (otcItemsResult?.data) {
      const otcItems = otcItemsResult?.data.find(
        otcItem => otcItem.itemNumber === scanResult.raw,
      );

      if (otcItems && transaction) {
        logUserSelection(UserLogTypes.ScannedOTC, {
          result: otcItems.description,
        });
        if (
          otcItems.itemType.id === ITEM_TYPE_IDS.METH_FREE &&
          transaction.pseCheckResult === 1
        ) {
          notification({
            title: 'PSE Items Locked',
            description:
              'The user has already passed the NPLEx check and these items cannot be edited.',
            type: 'warning',
            icon: 'Warning',
          });
        } else {
          const items = [otcItems].reduce(
            createTransactionOtcReducer(transaction),
            transaction.items || [],
          );
          updateTransaction({
            items,
          });
        }
      } else {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned OTC item couldn’t be found in the database.',
        });
      }
    }
  }

  return (
    <TransactionPage
      transaction={transaction}
      canDeleteTransaction={canDelete}
      totals={totals}
      settings={(settingsResult && settingsResult.data) || []}
      otcItems={otcItemsResult?.data || []}
      barcodes={(barcodesResult && barcodesResult.data) || EMPTY_BARCODES}
      multiselect={multiselect}
      enabledFeatures={enabledFeatures}
      navigate={navigate}
      onRemoveTransaction={({transactionId}: Transaction) => {
        updateSession(
          prevSession => ({
            transactions: prevSession.transactions.map(inv =>
              inv.transactionId === transactionId
                ? {...inv, isDeleted: true, isSynced: false}
                : inv,
            ),
          }),
          currentTransactionId,
        );
      }}
      onUpdateTransaction={updateTransaction}
      onUpdateTransactionItem={updateTransactionItem}
      setCurrentTransactionId={setCurrentTransactionId}
      onScan={onScan}
    />
  );
}

export const TransactionIntegration = memo(
  withRootPage(TransactionIntegrationComponent),
);
