import {
  BaseAPI,
  Configuration,
  FetchParams,
  RequestContext,
  ResponseContext,
} from '@emporos/api-enterprise';
import {invoke} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {
  AnalyticType,
  OfflineEndpointParams,
  endpointConfig,
  offlineEndpointConfig,
  refreshAuthIfNecessary,
  useAlertState,
  useAnalyticsProvider,
  useAuthentication,
  useNetworkAvailable,
} from '../';
import {
  FetchApiParams,
  MethodKeyOf,
  MethodParametersOf,
  MethodReturnTypeOf,
  PromiseResolveType,
} from './';
import {ConsoleLoggerVariant} from '../utils/console-logger';
import {useConsoleLogger} from '../contexts/ConsoleLoggingProvider';
import {useOidcAuth} from '../contexts/OidcAuthProvider';
import {DIFactory} from '../DIFactory';
import {CacheWrapper} from '../utils/global-wrappers/CacheWrapper';

export type ClientConstructor<T> = {new (config: Configuration): T};

export type UseOpenApiHook<T extends BaseAPI, K extends MethodKeyOf<T>> = {
  run: (...params: MethodParametersOf<T, K>) => MethodReturnTypeOf<T, K>;
  loading: boolean;
  data?: PromiseResolveType<MethodReturnTypeOf<T, K>>;
  error?: string;
  status?: number;
};

export type ApiVersion = '1.0' | '1.1' | '1.2' | '1.3' | '1.5'; // needs updated when version increments

export const useOpenApi = <T extends BaseAPI, K extends MethodKeyOf<T>>(
  clientConstructor: ClientConstructor<T>,
  method: K,
  version: ApiVersion,
  initialParams?: MethodParametersOf<T, K>,
): UseOpenApiHook<T, K> => {
  const {user, logout} = useAuthentication();
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();
  const {styledLog, logError} = useConsoleLogger();
  const {online, userNeedsRefreshed, setUserNeedsRefreshed} =
    useNetworkAvailable();
  const [loading, setLoading] = useState(false);
  const [data, setData] =
    useState<PromiseResolveType<MethodReturnTypeOf<T, K>>>();
  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<number>();
  const auth = useOidcAuth();
  const authStorageService = DIFactory.getAuthStorageService();

  const failSilently = (err: Error) => {
    logError(err);
    return null;
  };

  const dependency =
    initialParams?.length === 1 ? JSON.stringify(initialParams) : null;

  useEffect(() => {
    if (initialParams) {
      run(...initialParams).catch(e => logError(e));
    }
  }, [dependency]);

  const tokenPreMiddleware = useCallback(
    async (context: RequestContext): Promise<FetchParams | void> => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${user?.access_token}`);
      headers.append('Content-Type', `application/json;v=${version}`);
      return {
        url: context.url,
        init: {...context.init, headers: headers},
      };
    },
    [user],
  );

  const errorPostMiddleWare = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {response} = context;
      const {ok, status: _status, statusText} = response;
      setStatus(_status);
      if (!ok) {
        setError(statusText);
        setLoading(false);
        throw response;
      }
    },
    [],
  );

  const offlinePostMiddleware = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {url, response, init} = context;
      const {method: iMethod} = init;

      const offline = endpointConfig(url, iMethod)?.offline;
      if (offline) {
        await CacheWrapper.put('api-offline-cache', url, response).catch(
          failSilently,
        );
      }
    },
    [],
  );

  const _runOffline = async (
    ...params: MethodParametersOf<T, K>
  ): MethodReturnTypeOf<T, K> => {
    const compiledParams = buildOfflineEndpointParams(method, ...params);
    const offlineConfig = offlineEndpointConfig(compiledParams);

    if (offlineConfig?.url) {
      const response = await CacheWrapper.match(
        'api-offline-cache',
        offlineConfig.url,
      ).catch(failSilently);
      if (response) {
        const responseJson = await response.json();
        setData(responseJson);
        setLoading(false);
        return responseJson;
      } else {
        const error =
          'No cache available for offline use, method:' +
            method.toString() +
            ', url: ' +
            offlineConfig?.url ?? 'undefined';
        setError(error);
        console.log('Ofl Error: ', error);
        setLoading(false);
        throw new Error(error);
      }
    } else {
      const error =
        'Endpoint not configured for offline use, method:' + method.toString();
      setError(error);
      console.log('Ofl Error: ', error);
      setLoading(false);
      throw new Error(error);
    }
  };

  const _runOnline = useCallback(
    async (...params: MethodParametersOf<T, K>): MethodReturnTypeOf<T, K> => {
      const {promise: deferRequest, deferred} = getDeferRequest();
      const {CLIENT_API_URL} = process.env;

      const client = new clientConstructor(
        new Configuration({
          basePath: CLIENT_API_URL,
          fetchApi: (requestInfo: RequestInfo, requestInit?: RequestInit) => {
            deferred({requestInfo, requestInit});
            return fetch(requestInfo, requestInit);
          },
        }),
      );

      const invokePromise = invoke(
        client
          .withPreMiddleware(tokenPreMiddleware)
          .withPostMiddleware(errorPostMiddleWare)
          .withPostMiddleware(offlinePostMiddleware),
        method,
        ...params,
      ) as Promise<PromiseResolveType<MethodReturnTypeOf<T, K>>>;

      setStatus(undefined);
      const fetchParams = await deferRequest;
      const url = fetchParams.requestInfo as string;
      const config = endpointConfig(url, fetchParams.requestInit?.method);

      // if preferOffline get result from cache if any
      if (config?.preferOffline) {
        const response = await CacheWrapper.match('api-offline-cache', url);
        if (response) {
          const responseJson = await response.json();
          setData(responseJson);
          setLoading(false);
          //update cache with latest data from API through offlinePostMiddleware
          await invokePromise;
          return responseJson;
        }
      }

      try {
        const result = await invokePromise;
        setData(result);
        return result;
      } catch (e) {
        if (config?.offline) {
          const response = await CacheWrapper.match(
            'api-offline-cache',
            url,
          ).catch(failSilently);
          if (response) {
            const responseJson = await response.json();
            setData(responseJson);
            setLoading(false);
            return responseJson;
          }
        }
        const respJson = await (e as Response).json();

        config?.errorAlert &&
          notification({
            ...config.errorAlert,
            description: config.errorAlert.description ?? respJson.errors,
          });
        track(AnalyticType.ApiError, {
          error: error || '',
          url,
          headers: fetchParams.requestInit?.headers,
          method: fetchParams.requestInit?.method || 'GET',
          body: JSON.stringify(fetchParams.requestInit?.body),
        });
        setStatus(500);
        setError(`Error fetching ${String(method)} - v${version}`);
        styledLog('useOpenApi - Call Failed', ConsoleLoggerVariant.PINK, {
          error: error || '',
          url,
          headers: fetchParams.requestInit?.headers,
          method: fetchParams.requestInit?.method || 'GET',
          body: JSON.stringify(fetchParams.requestInit?.body),
        });
        return {} as MethodReturnTypeOf<T, K>;
      } finally {
        setLoading(false);
      }
    },
    [tokenPreMiddleware, errorPostMiddleWare, offlinePostMiddleware],
  );

  const run = useCallback(
    async (...params: MethodParametersOf<T, K>): MethodReturnTypeOf<T, K> => {
      setLoading(true);
      setError('');
      try {
        if (online) {
          await refreshAuthIfNecessary(
            online,
            user,
            userNeedsRefreshed,
            setUserNeedsRefreshed,
            auth.signinRefresh,
            authStorageService.storeUser,
            authStorageService.removeAuthInfo,
            logout,
          );
          return _runOnline(...params);
        } else return _runOffline(...params);
      } catch (e) {
        console.log('Error: ', e);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [_runOnline, _runOffline, userNeedsRefreshed, online],
  );

  const buildOfflineEndpointParams = <
    T extends BaseAPI,
    K extends MethodKeyOf<T>,
  >(
    method: string | number | symbol,
    ...params: MethodParametersOf<T, K>
  ) => {
    let allParams: MethodParametersOf<T, K>;
    if (initialParams)
      allParams = Object.assign({}, ...initialParams, ...params);
    else allParams = params;

    let siteId: string | null = null,
      stationId: string | null = null;
    Object.entries(allParams).forEach(param => {
      const paramObj = param as [string, string];
      if (paramObj[0] == 'siteId') {
        siteId = paramObj[1] ?? null;
      }
      if (paramObj[0] == 'stationId') {
        stationId = paramObj[1];
      }
    });

    return {
      siteId,
      stationId,
      method,
    } as OfflineEndpointParams;
  };

  return {run, loading, data, error, status};
};

export const getDeferRequest = () => {
  let deferred: (request: FetchApiParams) => void = () => {
    return;
  };
  const promise = new Promise<FetchApiParams>(resolve => {
    deferred = resolve;
  });
  return {
    promise,
    deferred,
  };
};
