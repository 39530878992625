import {
  AccessCode,
  Button,
  DisplayUser,
  Gutter,
  Header,
  LogosHilo,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  Size,
  Stack,
  Text,
  TextVariant as TV,
  Variant,
  VirtualizedList,
} from '@emporos/components';
import {EmptyNavbar} from '@emporos/components-pos';
import {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  IntermissionThemed,
  NavigationLogTypes,
  useAlertState,
  useLog,
  useNetworkAvailable,
} from '../../';
import {useOidcAuth} from '../../contexts/OidcAuthProvider';
import {DIFactory} from '../../DIFactory';
import moment from 'moment';
import {navigate} from '@reach/router';
import {User} from '@emporos/hilo-auth';

const appVersion =
  process.env.APP_VERSION !== undefined
    ? String(process.env.APP_VERSION)
    : null;
interface PreLoginProps {
  onRedirect: () => void | Promise<void>;
}

const VersionWrapper = styled.div`
  position: fixed;
  top: 99%;
  left: 99%;
  transform: translate(-100%, -100%);
`;

const CREDENTIAL_TAB = 1;
const ACCESS_CODE_TAB = 0;

const PreLoginPanelContainer = styled.div`
  width: 440px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PreLoginPanel = styled.div`
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  border-radius: 16px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 36px;
`;

const TabWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 16px;
`;

const TrademarkText = styled(Text)`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  hyphens: auto;
  line-height: 20px;
`;

const StyledScrollContainer = styled(ScrollContainer)`
  overflow: hidden auto;
  padding: 0;
`;

const AccessCodeContainer = styled(StyledScrollContainer)`
  padding: 12px 0 0;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  bottom: 14px;
  left: 12px;
`;

function PreLoginComponent({onRedirect}: PreLoginProps) {
  const {logUserSelection} = useLog();
  const {reset} = useAlertState();
  const {online, setTempCachedAccessCode, setUserNeedsRefreshed} =
    useNetworkAvailable();
  const auth = useOidcAuth();
  const [activeTab, setActiveTab] = useState(ACCESS_CODE_TAB);
  const [storedUsers, setStoredUsers] = useState<DisplayUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<DisplayUser | null>(null);
  const [welcomeHeader, setWelcomeHeader] = useState<string>('');
  const authStorageService = DIFactory.getAuthStorageService();

  useEffect(() => {
    const allStoredUsers = authStorageService.getAllStoredUsers();
    setStoredUsers(allStoredUsers);
  }, []);

  useEffect(() => {
    if (storedUsers.length > 0) {
      setActiveTab(ACCESS_CODE_TAB);
    } else {
      setActiveTab(CREDENTIAL_TAB);
    }
  }, [online, storedUsers]);

  useEffect(() => {
    if (storedUsers.length === 1) {
      //only 1 user, so skip the selection screen
      setSelectedUser(storedUsers[0]);
    }
  }, [storedUsers]);

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    logUserSelection(NavigationLogTypes.UserNavigating, {
      url: location.pathname,
    });
  }, [location.pathname]);

  const refresh = async (accessCode: string): Promise<boolean> => {
    if (!selectedUser) return false;
    const storedUser = authStorageService.getStoredUser(
      selectedUser.userKey,
      accessCode,
    );
    if (!storedUser) return false;
    const user = User.fromStorageString(storedUser.user);
    if (online) {
      await auth.signinRefresh(user);
    } else {
      await auth.storeUser(user);
      setUserNeedsRefreshed(user);
      setTempCachedAccessCode(accessCode);
      navigate('/sales');
    }
    return true;
  };

  const printableDate = (unixDate: number) => {
    const date = new Date(unixDate * 1000);
    return moment(date).format('MM/DD/YYYY h:mm:ss A');
  };

  const goBackToUserSelection = () => {
    setSelectedUser(null);
  };

  return (
    <>
      {auth.isLoading && <IntermissionThemed />}
      {!auth.isLoading && (
        <>
          <EmptyNavbar />
          <PreLoginPanelContainer>
            <Stack>
              <Row justify="center">
                {storedUsers.length > 0 && online && (
                  <TabWrapper>
                    <SegmentSlider
                      items={['Access Code', 'Manual']}
                      onSelectIndex={setActiveTab}
                      active={activeTab}
                    />
                  </TabWrapper>
                )}
                {activeTab === CREDENTIAL_TAB && online && (
                  <PreLoginPanel data-testid="manualLoginPanel">
                    <Stack justify="center" gutter={Gutter.XL}>
                      <Row justify="center">
                        <LogosHilo.HiloHorizontalColor height={50} />
                      </Row>
                      <Row justify="center">
                        <Stack align="center" gutter={Gutter.XXS}>
                          <Text align="center" variant={TV.T3}>
                            Login Needed
                          </Text>

                          <Text align="center" variant={TV.MainLight}>
                            To access the application and manage your pharmacy
                            commerce operations, please login below. You will be
                            redirected to verify your login credentials.
                          </Text>
                        </Stack>
                      </Row>
                      <Row justify="center" style={{marginTop: '36px'}}>
                        <Button flex onClick={onRedirect}>
                          Login with Emporos ID
                        </Button>
                      </Row>
                    </Stack>
                  </PreLoginPanel>
                )}
                {activeTab === CREDENTIAL_TAB && !online && (
                  <PreLoginPanel>
                    <Stack justify="center" gutter={Gutter.XL}>
                      <Row justify="center">
                        <LogosHilo.HiloMarkColor height={80} />
                      </Row>
                      <Row justify="center">
                        <Stack align="center" gutter={Gutter.XXS}>
                          <Text align="center" variant={TV.T3}>
                            Login Needed
                          </Text>

                          <Text align="center" variant={TV.MainLight}>
                            Offline authentication is only available on devices
                            with an open session.
                          </Text>
                        </Stack>
                      </Row>
                      <Row justify="center" style={{marginTop: '36px'}}>
                        <Button
                          disabled={!online}
                          flex
                          icon={'NetworkNone'}
                          onClick={onRedirect}
                        >
                          Offline
                        </Button>
                      </Row>
                    </Stack>
                  </PreLoginPanel>
                )}
                {activeTab === ACCESS_CODE_TAB && !selectedUser && (
                  <PreLoginPanel>
                    <StyledHeader title={'Active User Sessions'} />
                    <StyledScrollContainer>
                      <VirtualizedList<DisplayUser>
                        data={storedUsers}
                        listHeight={410}
                        rowHeight={95}
                        renderItem={(user: DisplayUser) => (
                          <RowItem
                            inactive={false}
                            title={user.name}
                            subtitle={`Session Date: ${printableDate(
                              user.iat,
                            )}`}
                            key={user.userKey}
                            selected={false}
                            onClick={() => setSelectedUser(user)}
                          />
                        )}
                      />
                    </StyledScrollContainer>
                  </PreLoginPanel>
                )}
                {activeTab === ACCESS_CODE_TAB && selectedUser && (
                  <PreLoginPanel data-testid="accessCodeLoginPanel">
                    <StyledHeader title={welcomeHeader} />
                    <TrademarkText variant={TV.MainLight}>
                      To access the application and manage your pharmacy
                      commerce operations, please login below.
                    </TrademarkText>
                    <AccessCodeContainer>
                      <Stack gutter={Gutter.L}>
                        <Row>
                          <AccessCode
                            mode="login"
                            codeLength={6}
                            setHeaderTitle={setWelcomeHeader}
                            setAccessCode={refresh}
                            selectedUser={selectedUser}
                          ></AccessCode>
                        </Row>
                      </Stack>
                    </AccessCodeContainer>
                  </PreLoginPanel>
                )}
              </Row>
            </Stack>
            {activeTab === ACCESS_CODE_TAB &&
              selectedUser &&
              storedUsers.length > 1 && (
                <BackButtonContainer>
                  <Button
                    size={Size.Small}
                    variant={Variant.Link}
                    type="button"
                    onClick={goBackToUserSelection}
                    loading={false}
                  >
                    Back
                  </Button>
                </BackButtonContainer>
              )}
          </PreLoginPanelContainer>
          {appVersion && (
            <VersionWrapper>
              <Text align="center" variant={TV.MainLight}>
                {appVersion}
              </Text>
            </VersionWrapper>
          )}
        </>
      )}
    </>
  );
}

export const PreLogin = memo(PreLoginComponent);
