import {TransactionExtra} from '@emporos/api-enterprise';

export const TransactionSourceKey = 'TransactionSource';
export enum TransactionSource {
  Hilo,
  PatientPay,
}

export const getTransactionSource = (transactionExtras: TransactionExtra[]) => {
  const source = transactionExtras.find(
    extra => extra.dataKey === TransactionSourceKey,
  )?.dataValue;

  return (
    TransactionSource[source as keyof typeof TransactionSource] ??
    TransactionSource.Hilo
  );
};
