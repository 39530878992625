import {User} from '@emporos/hilo-auth';

export const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace('#', '?'));
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  return false;
};

const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

export const loginError = normalizeErrorFn('Login failed');

export async function refreshAuthIfNecessary(
  online: boolean,
  user: User | null,
  userNeedsRefreshed: User | null,
  setUserNeedsRefreshed: (date: User | null) => void,
  signinRefreshFn: (user?: User | undefined) => Promise<User>,
  storeUserFn: (user: User, accessCode: string) => void,
  removeAuthInfoFn: (user: User) => void,
  logoutFn: () => void,
  accessCode?: string,
): Promise<User | null> {
  let retVal = user;
  if (online && userNeedsRefreshed) {
    const userCopy = {...userNeedsRefreshed} as User;
    setUserNeedsRefreshed(null);
    try {
      retVal = await signinRefreshFn(userCopy);
      if (accessCode) storeUserFn(retVal, accessCode);
    } catch (e) {
      if (user) {
        removeAuthInfoFn(userCopy);
      }
      logoutFn();
      if (typeof e === 'string') {
        throw new Error('Token refresh failed: ' + e || 'unknown error');
      } else if (e instanceof Error) {
        throw new Error(
          'Token refresh failed: ' + e.message || 'unknown error',
        );
      } else {
        throw new Error('Token refresh failed: unknown error');
      }
    }
  }
  return retVal;
}
