import {NotificationAlert} from '@emporos/components';

export type EndpointsConfig = Record<
  string,
  Record<
    string,
    {
      errorAlert?: Omit<NotificationAlert, 'id'>;
      offline?: boolean;
      preferOffline?: boolean;
    }
  >
>;

export type OfflineEndpointsConfig = Record<
  string,
  {
    url: string;
  }
>;

export type OfflineEndpointParams = {
  siteId: string | null;
  stationId: string | null;
  method: string | number | symbol;
};

export const getEndpointsConfig = (): EndpointsConfig => {
  return {
    GET: {
      '(.*)(\\/client\\/barcodes)': {
        offline: true,
      },
      '(.*)(\\/client\\/otc-items)(.*)': {
        offline: true,
        preferOffline: true,
      },
      '(.*)(\\/client\\/tax-groups)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/payment-tenders)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/relationships)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/settings)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/payment-options\\?siteId=)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/signatures\\/types)(.*)': {
        offline: true,
      },
      '(.*)(\\/client\\/eligibility\\?q=)(.*)': {
        errorAlert: {
          title: 'Searching Failed',
          description:
            'Something happened while searching eligibility. Please try searching again.',
          icon: 'Warning',
          type: 'warning',
        },
      },
      '(.*)(\\/client\\/sites)(.*)': {
        preferOffline: false,
        offline: true,
        errorAlert: {
          title: 'Failed To Get Sites',
          description:
            'Something happened while loading sites. Please check your internet connection and reload the app.',
          icon: 'Warning',
          type: 'warning',
        },
      },
      '(.*)(\\/client\\/clientCacheSessionsMyGet)(.*)': {
        preferOffline: false,
        offline: true,
        errorAlert: {
          title: 'Failed To Get Get Session',
          description:
            'Something happened while loading Session. Please check your internet connection and reload the app.',
          icon: 'Warning',
          type: 'warning',
        },
      },
    },
    POST: {
      '(.*)(\\/accounts\\/ar)': {
        errorAlert: {
          title: 'Create AR Account',
          description:
            'The new AR Account could not be created. Please try again.',
          icon: 'Warning',
          type: 'warning',
        },
      },
    },
    PUT: {
      '(.*)(\\/client\\/will-call)(.*)': {
        errorAlert: {
          title: 'Will Call Error',
          description:
            'Customer prescription info could not be pulled down. Please try again.',
          icon: 'Warning',
          type: 'warning',
        },
      },
    },
    PATCH: {},
    DELETE: {},
  };
};

export const getOfflineEndpointsConfig = (
  params: OfflineEndpointParams,
): OfflineEndpointsConfig => {
  const {CLIENT_API_URL} = process.env;

  return {
    clientSitesGet: {
      url: `${CLIENT_API_URL}/client/sites`,
    },
    clientBarcodesGet: {
      url: params.siteId
        ? `${CLIENT_API_URL}/client/barcodes?siteId={siteId}`.replace(
            `{${'siteId'}}`,
            encodeURIComponent(String(params.siteId)),
          )
        : `${CLIENT_API_URL}/client/barcodes`,
    },
    clientOtcItemsSiteIdGet: {
      url: params.siteId
        ? `${CLIENT_API_URL}/client/otc-items/{siteId}`.replace(
            `{${'siteId'}}`,
            encodeURIComponent(String(params.siteId)),
          )
        : `${CLIENT_API_URL}/client/otc-items`,
    },
    clientTaxGroupsGet: {
      url: `${CLIENT_API_URL}/client/tax-groups`,
    },
    clientSettingsGet: {
      url:
        params.siteId && params.stationId
          ? `${CLIENT_API_URL}/client/settings?siteId={siteId}&stationId={stationId}`
              .replace(
                `{${'siteId'}}`,
                encodeURIComponent(String(params.siteId)),
              )
              .replace(
                `{${'stationId'}}`,
                encodeURIComponent(String(params.stationId)),
              )
          : `${CLIENT_API_URL}/client/settings`,
    },
    clientRelationshipsGet: {
      url: `${CLIENT_API_URL}/client/relationships`,
    },
    clientPaymentTendersGet: {
      url: `${CLIENT_API_URL}/client/payment-tenders`,
    },
    clientPaymentOptionsGet: {
      url: params.siteId
        ? `${CLIENT_API_URL}/client/payment-options?siteId={siteId}`.replace(
            `{${'siteId'}}`,
            encodeURIComponent(String(params.siteId)),
          )
        : `${CLIENT_API_URL}/client/payment-options`,
    },
    clientSignaturesTypesGet: {
      url: `${CLIENT_API_URL}/client/signatures/types`,
    },
  };
};
