import {
  ApiResponse,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError,
  Transaction,
  TransactionFromJSON,
} from '@emporos/api-enterprise';
import {EncryptedBaseApi} from './EncryptedBaseApi';

export class EncryptedTransactionsApi extends EncryptedBaseApi {
  /**
   * Inserts an transaction
   */
  async InsertTransactionEncryptedRaw(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<ApiResponse<Transaction>> {
    this.checkRequiredParameters(
      sessionId,
      cipherTextByteArray,
      'EncryptedTransactionsApi.InsertTransactionEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      ),
      this.ConvertToEncodedString(cipherTextByteArray),
      headerParameters,
      queryParameters,
      'POST',
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionFromJSON(jsonValue),
    );
  }

  /**
   * Inserts an transaction
   */
  async InsertTransactionEncrypted(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<Transaction> {
    const response = await this.InsertTransactionEncryptedRaw(
      sessionId,
      cipherTextByteArray,
    );
    return await response.value();
  }

  /**
   * Updates an transaction
   */
  async UpdateTransactionEncryptedRaw(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<ApiResponse<Transaction>> {
    this.checkRequiredParameters(
      sessionId,
      cipherTextByteArray,
      'EncryptedTransactionsApi.UpdateTransactionEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      ),
      this.ConvertToEncodedString(cipherTextByteArray),
      headerParameters,
      queryParameters,
      'PUT',
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionFromJSON(jsonValue),
    );
  }

  /**
   * Updates an transaction
   */
  async UpdateTransactionEncrypted(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<Transaction> {
    const response = await this.UpdateTransactionEncryptedRaw(
      sessionId,
      cipherTextByteArray,
    );
    return await response.value();
  }

  checkRequiredParameters(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
    methodName: string,
  ): void {
    if (sessionId === null || sessionId === undefined) {
      throw new RequiredError(
        'sessionId',
        'Required parameter sessionId was null or undefined when calling ' +
          methodName,
      );
    }

    if (cipherTextByteArray === null || cipherTextByteArray === undefined) {
      throw new RequiredError(
        'cipherTextByteArray',
        'Required parameter cipherTextByteArray was null or undefined when calling ' +
          methodName,
      );
    }
  }
}
